import { connect } from 'react-redux';
import { ManageLocationDisplay } from './ManageLocationDisplay.jsx';
import {
  fetchLocationDetails,
  updateProjectDetails,
  updateLocationDetails,
  setAutocompleteSelectedOrganizations,
  setAutocompleteSelectedServiceContactPersons,
  setAutocompleteSelectedContactPersons,
  setAutocompleteSelectedProjectManagers,
  setOnlineSwitchValue,
  setHideSwitchValue,
  resetLocationDashboardState
} from './redux/manageLocationActions.js';
import {
  locationDetailsSelector,
  selectedOrganizationSelector,
  autocompleteSelectedServiceContactPersonsSelector,
  autocompleteSelectedContactPersonSelector,
  autocompleteSelectedProjectManagersSelector,
  hideSwitchValueSelector,
  onlineSwitchValueSelector
} from './redux/manageLocationSelectors';
import { fetchDevices } from 'components/content/devices/redux/devicesActions.js';
import { fetchChannelsListOfLocation } from './channels/redux/channelsListActions.js';

const mapStateToProps = (state, ownProps) => {
  const viewModel = locationDetailsSelector(state);
  return {
    locationId: ownProps.locationId,
    viewModel: viewModel,
    selectedOrganizations: findOrganizationsBySensightId(
      viewModel.organizations,
      selectedOrganizationSelector(state)
    ),
    selectedServiceContactPersons: findLocationServiceContacts(
      viewModel.contacts,
      autocompleteSelectedServiceContactPersonsSelector(state)
    ),
    selectedContactPerson: autocompleteSelectedContactPersonSelector(state),
    selectedProjectManager: autocompleteSelectedProjectManagersSelector(state),
    hideSwitchValue: hideSwitchValueSelector(state),
    onlineSwitchValue: onlineSwitchValueSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchChannelsListOfLocation(locationId, locationSensightId) {
    dispatch(fetchChannelsListOfLocation(locationId, locationSensightId));
  },
  fetchLocationDetails(id) {
    dispatch(fetchLocationDetails(id));
  },
  fetchDevices() {
    dispatch(fetchDevices());
  },
  updateLocationDetails(locationDetails) {
    dispatch(updateLocationDetails(locationDetails));
  },
  updateProjectDetails(projectDetails) {
    dispatch(updateProjectDetails(projectDetails));
  },
  setAutocompleteSelectedOrganizations(selectedOrganizations) {
    dispatch(setAutocompleteSelectedOrganizations(selectedOrganizations));
  },
  setAutocompleteServiceContactPersons(selectedServiceContactPersons) {
    dispatch(
      setAutocompleteSelectedServiceContactPersons(
        selectedServiceContactPersons
      )
    );
  },
  setAutocompleteContactPerson(selectedContactPerson) {
    dispatch(setAutocompleteSelectedContactPersons(selectedContactPerson));
  },
  setAutocompleteProjectManagers(selectedProjectManager) {
    dispatch(setAutocompleteSelectedProjectManagers(selectedProjectManager));
  },
  setOnlineSwitchValue(switchValue) {
    dispatch(setOnlineSwitchValue(switchValue));
  },
  setHideSwitchValue(switchValue) {
    dispatch(setHideSwitchValue(switchValue));
  },
  resetLocationDashboardState() {
    dispatch(resetLocationDashboardState());
  }
});

export const ManageLocationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageLocationDisplay);

function findOrganizationsBySensightId(organizations, currentOrganizations) {
  const result = [];

  currentOrganizations.forEach(currentOrganizationId => {
    const organization = organizations.find(
      org => org.sensightId === currentOrganizationId
    );
    if (organization) {
      result.push(organization);
    }
  });
  return result;
}

function findLocationServiceContacts(contacts, serviceContactIds) {
  const result = [];
  serviceContactIds.forEach(contactId => {
    const contact = contacts.find(con => con.sensightId === contactId);
    if (contact) {
      result.push(contact);
    }
  });
  return result;
}
