import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { InputLabel, Grid } from '@mui/material';
import { Field, Form } from 'formik';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import TimePicker from 'components/imd-components/ImdTimePicker';
import { TextField as formikTextField } from 'formik-mui';
import imdTimeZoneMenuItems from 'components/imd-components/imdTimeZoneMenuItems.jsx';

export const DetailsForm = ({
  viewModel,
  switchTime,
  setSwitchTime,
  locationFormRef,
  selectedContactPerson,
  selectedProjectManager,
  updateLocationDetails,
  metaDataGrid,
  setAutocompleteContactPerson,
  setAutocompleteProjectManagers
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderLocationTypeMenuItems = () => {
    const menuItems = viewModel.locationTypes.map(key => {
      return (
        <MenuItem key={key.id} value={key.id}>
          {key.name}
        </MenuItem>
      );
    });

    return menuItems;
  };

  return (
    <FormikWithRef
      ref={locationFormRef}
      initialValues={{
        name: viewModel.location.name ?? '',
        referenceNumber: viewModel.location.referenceNumber ?? '',
        projectNumber: viewModel.location.projectNumber ?? '',
        projectManager: selectedProjectManager ?? { email: '' },
        contactPerson: selectedContactPerson ?? { email: '' },
        city: viewModel.location.city ?? '',
        address: viewModel.location.address ?? '',
        latLngLatitude: viewModel.location.latLngLatitude ?? '',
        latLngLongitude: viewModel.location.latLngLongitude ?? '',
        locationType: viewModel.location.locationTypeId ?? '',
        timeZone:
          viewModel.measurementServiceLocation.timeZone ?? 'Europe/Amsterdam'
      }}
      validate={values => {
        if (selectedContactPerson) {
          if (selectedContactPerson.email !== '') {
            values.contactPerson = selectedContactPerson;
          }
        }

        if (selectedProjectManager) {
          if (selectedProjectManager.email !== '') {
            values.projectManager = selectedProjectManager;
          }
        }
      }}
      enableReinitialize={false}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        if (selectedContactPerson) {
          values.contact = selectedContactPerson.sensightId;
        }

        if (selectedProjectManager) {
          values.projectManager = selectedProjectManager.sensightId;
        }

        values.metadata = JSON.stringify(metaDataGrid);
        values.switchTime = switchTime.toLocaleTimeString('nl-NL');
        values.measurementId = viewModel.measurementServiceLocation.id;
        values.id = viewModel.location.id;
        values.sensightId = viewModel.location.sensightId;
        updateLocationDetails(values);
      }}
    >
      {props => (
        <Form onSubmit={props.submitForm}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                className={classes.textField}
                id="name"
                name="name"
                label={t('content.locationdashboard.form.name')}
                fullWidth
                onChange={props.handleChange}
                value={props.values.name}
                variant="standard"
              />
              <TextField
                className={classes.textField}
                id="referenceNumber"
                name="referenceNumber"
                label={t('content.locationdashboard.form.referenceNumber')}
                fullWidth
                onChange={props.handleChange}
                value={props.values.referenceNumber}
                variant="standard"
              />
              <TextField
                className={classes.textField}
                id="projectNumber"
                name="projectNumber"
                label={t('content.locationdashboard.form.projectNumber')}
                fullWidth
                onChange={props.handleChange}
                value={props.values.projectNumber}
                variant="standard"
              />
              <Autocomplete
                options={viewModel.contacts}
                getOptionLabel={option => option.email}
                defaultValue={selectedContactPerson}
                onChange={(event, value) => {
                  setAutocompleteContactPerson(value);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    name="contactPerson"
                    type="text"
                    label={t('content.locationdashboard.form.contactPerson')}
                    fullWidth
                    className={classes.textField}
                    variant="standard"
                  />
                )}
              />
              <Autocomplete
                options={viewModel.contacts}
                getOptionLabel={option => option.email}
                defaultValue={selectedProjectManager}
                onChange={(event, value) => {
                  setAutocompleteProjectManagers(value);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    name="projectManager"
                    type="text"
                    label={t('content.locationdashboard.form.projectManager')}
                    fullWidth
                    className={classes.textField}
                    variant="standard"
                  />
                )}
              />
              <TextField
                className={classes.textField}
                id="city"
                name="city"
                label={t('content.locationdashboard.form.city')}
                fullWidth
                onChange={props.handleChange}
                value={props.values.city}
                variant="standard"
              />
              <TextField
                className={classes.textField}
                id="address"
                name="address"
                label={t('content.locationdashboard.form.address')}
                fullWidth
                onChange={props.handleChange}
                value={props.values.address}
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textField}
                id="latLngLatitude"
                name="latLngLatitude"
                label={t('content.locationdashboard.form.latLngLatitude')}
                fullWidth
                onChange={props.handleChange}
                value={props.values.latLngLatitude}
                variant="standard"
              />
              <TextField
                id="latLngLongitude"
                name="latLngLongitude"
                label={t('content.locationdashboard.form.latLngLongitude')}
                className={classes.lastInputField}
                fullWidth
                onChange={props.handleChange}
                value={props.values.latLngLongitude}
                variant="standard"
              />
              <InputLabel>
                {t('content.locationdashboard.form.locationType')}
              </InputLabel>
              <Select
                id="locationType"
                name="locationType"
                fullWidth
                onChange={props.handleChange}
                value={props.values.locationType}
                variant="standard"
              >
                {renderLocationTypeMenuItems()}
              </Select>
              <TimePicker
                labelText={t('content.locationdashboard.form.switchTime')}
                saveAction={setSwitchTime}
                switchTime={switchTime}
                className={classes.customInputField}
              />
              <Field
                name="timeZone"
                type="select"
                label={t('content.profile.timezone.card.input.text')}
                select
                fullWidth
                className={`${classes.textField} ${classes.customInputField}`}
                component={formikTextField}
                variant="standard"
              >
                {imdTimeZoneMenuItems}
              </Field>
            </Grid>
          </Grid>
        </Form>
      )}
    </FormikWithRef>
  );
};

const useStyles = makeStyles(() => ({
  lastInputField: {
    marginBottom: '16px'
  },
  textField: {
    marginBottom: '8px'
  },
  customInputField: {
    marginTop: '8px'
  }
}));
