import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import ImdCard from 'components/imd-components/ImdCard';
import ImdButton from 'components/imd-components/ImdButton';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import { Tabs, Tab, Typography } from '@mui/material';
import { FileUploadContainer } from './fileupload/FileUploadContainer';
import { DevicePlacementsTableContainer } from './devicePlacements/DevicePlacementsTableContainer';
import { ChannelsDataTableContainer } from './channels/channelsDataTableContainer';
import CircularProgress from '@mui/material/CircularProgress';
import {
  isEmptyObject,
  getDateFromTimeString,
  cloneArray
} from 'utils/helperMethods';
import { DetailsForm } from './managelocationforms/DetailsForm';
import { ProjectForm } from './managelocationforms/ProjectForm';
import ImdMetaDataForm from 'components/imd-components/ImdMetaDataForm';

export function ManageLocationDisplay({
  fetchLocationDetails,
  fetchDevices,
  fetchChannelsListOfLocation,
  viewModel,
  locationId,
  updateLocationDetails,
  updateProjectDetails,
  setAutocompleteSelectedOrganizations,
  selectedOrganizations,
  setAutocompleteServiceContactPersons,
  selectedServiceContactPersons,
  setAutocompleteContactPerson,
  setAutocompleteProjectManagers,
  selectedContactPerson,
  selectedProjectManager,
  onlineSwitchValue,
  setOnlineSwitchValue,
  hideSwitchValue,
  setHideSwitchValue,
  resetLocationDashboardState
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);
  const [switchTime, setSwitchTime] = React.useState(
    new Date(2020, 1, 1, 0, 0, 0, 0)
  );

  const [initialMetadata, setInitialMetadata] = useState(null);

  const projectFormRef = useRef();
  const locationFormRef = useRef();

  const [metadata, setMetadata] = useState();
  const metadataForm = useRef();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    resetLocationDashboardState();
    fetchLocationDetails(locationId);
    fetchDevices();
  }, []);

  React.useEffect(() => {
    if (
      viewModel.measurementServiceLocation &&
      viewModel.measurementServiceLocation.id
    )
      fetchChannelsListOfLocation(
        viewModel.measurementServiceLocation.id,
        viewModel.measurementServiceLocation.sensightId
      );
  }, [viewModel.measurementServiceLocation]);

  React.useEffect(() => {
    if (viewModel.location.metadata) {
      setInitialMetadata(viewModel.location.metadata);
    } else {
      setInitialMetadata('{}');
    }
  }, [viewModel.location.metadata]);

  React.useEffect(() => {
    if (
      viewModel.measurementServiceLocation.switchTime !== null &&
      viewModel.measurementServiceLocation.switchTime !== undefined
    ) {
      setSwitchTime(
        getDateFromTimeString(viewModel.measurementServiceLocation.switchTime)
      );
    }
  }, [viewModel.measurementServiceLocation.switchTime]);

  const handleSubmit = async () => {
    let currentmetadata = '';
    if (metadataForm.current && metadataForm.current.current) {
      await metadataForm.current.current.instance.saveEditData();
      currentmetadata = cloneArray(
        metadataForm.current.current.instance.getDataSource()._items
      );

      setMetadata(currentmetadata);
      setInitialMetadata(JSON.stringify(currentmetadata));
    }
    projectFormRef.current.handleSubmit();
    locationFormRef.current.handleSubmit();
  };

  const clearForms = () => {
    locationFormRef.current.resetForm();
    projectFormRef.current.resetForm();
  };

  const locationContent = (
    <DetailsForm
      metaDataGrid={metadata}
      viewModel={viewModel}
      setSwitchTime={setSwitchTime}
      switchTime={switchTime}
      locationFormRef={locationFormRef}
      selectedContactPerson={selectedContactPerson}
      selectedProjectManager={selectedProjectManager}
      updateLocationDetails={updateLocationDetails}
      // handleValidateLocationForm={handleValidateLocationForm}
      setAutocompleteContactPerson={setAutocompleteContactPerson}
      setAutocompleteProjectManagers={setAutocompleteProjectManagers}
    />
  );

  const projectContent = (
    <ProjectForm
      viewModel={viewModel}
      projectFormRef={projectFormRef}
      onlineSwitchValue={onlineSwitchValue}
      selectedOrganizations={selectedOrganizations}
      // handleValidateLocationForm={selectedOrganizations}
      setAutocompleteContactPerson={updateProjectDetails}
      hideSwitchValue={hideSwitchValue}
      setHideSwitchValue={setHideSwitchValue}
      setOnlineSwitchValue={setOnlineSwitchValue}
      selectedServiceContactPersons={selectedServiceContactPersons}
      selectedProjectManager={selectedProjectManager}
      updateProjectDetails={updateProjectDetails}
      setAutocompleteSelectedOrganizations={
        setAutocompleteSelectedOrganizations
      }
      setAutocompleteServiceContactPersons={
        setAutocompleteServiceContactPersons
      }
    />
  );

  const locationTabContent = (
    <Grid container spacing={2} className={classes.tabContent}>
      <Grid item xs={12} lg={8}>
        <ImdCard
          title={t('content.locationdashboard.card.locationDetails')}
          content={
            !isEmptyObject(location) ? locationContent : <p>Fetching...</p>
          }
          variant="secondary"
          cardHeight="100%"
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <ImdCard
          title={t('content.locationdashboard.card.manage')}
          content={projectContent}
          variant="secondary"
          cardHeight="100%"
        />
      </Grid>
      <Grid item xs={12}>
        <ImdMetaDataForm
          ref={metadataForm}
          metadata={initialMetadata}
        ></ImdMetaDataForm>
      </Grid>
      <Grid item lg={12} md={5} sm={12}>
        <div className={classes.actionButtons}>
          <ImdButton
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              handleSubmit();
            }}
            startIcon={<SaveIcon />}
          >
            {t('general.save')}
          </ImdButton>
          <ImdButton
            color="inherit"
            size="large"
            startIcon={<ClearIcon />}
            onClick={() => clearForms()}
          >
            {t('general.cancel')}
          </ImdButton>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        classes={{
          root: classes.tabsRoot,
          indicator: classes.indicator
        }}
        aria-label="disabled tabs example"
      >
        <Tab
          label={t('content.locationdashboard.tab.locationDetails')}
          className={classes.tab}
        />
        <Tab
          label={t('content.locationdashboard.tab.files')}
          className={classes.tab}
        />
        <Tab
          label={t('content.locationdashboard.tab.devices')}
          className={classes.tab}
        />
        <Tab
          label={t('content.locationdashboard.tab.channels')}
          className={classes.tab}
        />
      </Tabs>
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== 0}
        id={`simple-tabpanel-${0}`}
        aria-labelledby={`simple-tab-${0}`}
      >
        {isEmptyObject(viewModel.location) ? (
          <div className={classes.spinnerWrapper}>
            <CircularProgress className={classes.spinner} />
          </div>
        ) : value === 0 ? (
          locationTabContent
        ) : (
          ''
        )}
      </Typography>
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== 1}
        id={`simple-tabpanel-${0}`}
        aria-labelledby={`simple-tab-${0}`}
      >
        {value === 1 ? <FileUploadContainer locationId={locationId} /> : ''}
      </Typography>
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== 2}
        id={`simple-tabpanel-${0}`}
        aria-labelledby={`simple-tab-${0}`}
      >
        {value === 2 ? (
          <DevicePlacementsTableContainer locationId={locationId} />
        ) : (
          ''
        )}
      </Typography>
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== 3}
        id={`simple-tabpanel-${0}`}
        aria-labelledby={`simple-tab-${0}`}
      >
        {value === 3 ? (
          <ChannelsDataTableContainer
            locationId={viewModel.measurementServiceLocation.id}
            locationSensightId={viewModel.location.sensightId}
          />
        ) : (
          ''
        )}
      </Typography>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  tabs: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)'
  },
  tabsRoot: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px 5px 0 0'
  },
  tab: {
    color: '#FFFFFF !important'
  },
  indicator: {
    backgroundColor: '#ffffff'
  },
  tabContent: {
    paddingTop: '12px',
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  actionButtons: {
    display: 'flex',
    gap: '8px',
    marginTop: '8px',
    marginBottom: '12px'
  },
  errorMessage: {
    color: 'red'
  },
  firstWrapper: {
    marginBottom: '8px'
  },
  switchWrapper: {
    display: 'flex'
  },
  switchLabel: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  switchItem: {
    marginLeft: 'auto'
  },
  lastInputField: {
    marginBottom: '16px'
  },
  textField: {
    marginBottom: '8px'
  },
  spinnerWrapper: {
    height: '75vh',
    display: 'flex',
    justifyContent: 'center'
  },
  spinner: {
    marginTop: 'auto',
    marginBottom: 'auto'
  }
}));
