import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ImdButton from 'components/imd-components/ImdButton';
import { RenderChartContainer } from '../renderChartComponent/renderChartContainer';
import image from '../grafiek_weergeven.png';
import { readVisualisationLink, cloneObject } from 'utils/helperMethods';
import { KpiContainer } from 'components/imd-components/kpi/KpiContainer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'redux/user/currentUserSelector';

export function DisplayChartComponentDisplay({
  channelData,
  measurements,
  displayChart,
  executeDisplayChart,
  clearChartData,
  setChartRendered,
  visualizeLink,
  importVisualizeLink,
  visualizeFromTime,
  visualizeUntilTime,
  renderKey,
  isLoading,
  logItems
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector(state => currentUserSelector(state));

  useEffect(async () => {
    clearChartData();
    setChartRendered(false);
    if (visualizeLink) {
      importVisualizeLink(await readVisualisationLink(user, visualizeLink));
    }
  }, []);

  const handleButtonClick = () => {
    if (!isLoading) {
      executeDisplayChart();
    }
  };

  const fetchButton = (
    <>
      <ImdButton
        variant="contained"
        color="primary"
        className={classes.button}
        size={'large'}
        disabled={isLoading}
        onClick={handleButtonClick}
      >
        {t('content.visualize.toolbar.link.showchart')}
      </ImdButton>
    </>
  );

  const renderVisualizeItems = () => {
    let visualizeItems = [];
    let channelIdsToSplice = [];
    let channelDataClone = cloneObject(channelData);
    findKpiItems(visualizeItems, channelIdsToSplice);

    channelDataClone = removeNotedKpiIdsFromChannelData(
      channelIdsToSplice,
      channelDataClone
    );

    if (channelData.length > 0) {
      if (channelDataClone.length > 0) {
        BuildCombinedChart(channelDataClone, visualizeItems);
      }
    }

    return visualizeItems;
  };

  // Voeg de rest van de kanalen toe aan de gecombineerde grafiek
  function BuildCombinedChart(channelDataClone, visualizeItems) {
    let measurementsWithoutKpis = [];
    for (let index = 0; index < channelDataClone.length; index++) {
      const channel = channelDataClone[index];
      if (measurements[channel.id]) {
        measurementsWithoutKpis = measurementsWithoutKpis.concat(
          measurements[channel.id]
        );
      }
    }
    visualizeItems.push(
      <RenderChartContainer
        visualize
        key={visualizeItems.length + 1}
        dataSources={channelDataClone}
        measurements={measurementsWithoutKpis}
        visualizeFromTime={visualizeFromTime}
        visualizeUntilTime={visualizeUntilTime}
        renderKey={renderKey}
        logItems={logItems}
      />
    );
  }

  // We halen de visualisatieItems voor KPI's uit de ChannelDataClone, zodat we deze niet weergeven in de gecombineerde grafiek.
  function removeNotedKpiIdsFromChannelData(
    channelIdsToSplice,
    channelDataClone
  ) {
    for (let index = 0; index < channelIdsToSplice.length; index++) {
      const idToSplice = channelIdsToSplice[index];
      channelDataClone = channelDataClone.filter(c => c.id !== idToSplice);
    }
    return channelDataClone;
  }

  // KPI's worden los van elkaar weer gegeven, dus voeg ze toe aan de React.Element array en noteer het channelId.
  function findKpiItems(visualizeItems, channelIdsToSplice) {
    for (let index = 0; index < channelData.length; index++) {
      const channelToVisualize = channelData[index];
      if (channelToVisualize.chartTypeName === 'kpi') {
        visualizeItems.push(
          <div className={classes.kpiContainer} key={'kpi' + index}>
            <KpiContainer
              visualize
              dataSources={[channelToVisualize]}
              measurements={measurements[channelToVisualize.id]}
              visualizeFromTime={visualizeFromTime}
              visualizeUntilTime={visualizeUntilTime}
              renderKey={renderKey + index}
              defaultOpen={true}
              logItems={logItems}
            />
          </div>
        );
        channelIdsToSplice.push(channelToVisualize.id);
      }
    }
  }

  const graph = (
    <>
      {displayChart == true ? (
        <div>{renderVisualizeItems()}</div>
      ) : (
        <div className={classes.container}>
          {fetchButton}
          <img
            src={image}
            alt={t('content.visualize.toolbar.link.showchart')}
            className={classes.image}
          />
        </div>
      )}
    </>
  );

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.graph}>{graph}</div>
        </CardContent>
      </Card>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: '16px'
  },
  graph: {
    marginTop: '0px',
    marginBottom: '0px'
  },
  container: {
    position: 'relative'
  },
  image: {
    width: '100%',
    height: 'auto'
  },
  button: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  },
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  kpiContainer: {
    marginBottom: '0px'
  }
}));
