import { createReducer } from 'utils/createReducer';
import {
  SHOW_DIALOG,
  HIDE_DIALOG,
  FETCH_LOCATIONS_REQUESTED,
  IS_FETCHING_CHANNEL_DIALOG_LOCATIONS,
  IS_FETCHING_CHANNEL_DIALOG_DEVICES,
  HAS_STARTED_FETCHING_LOCATIONS_AND_DEVICES,
  HAS_FINISHED_FETCHING_LOCATIONS_AND_DEVICES,
  FETCH_DEVICES_REQUESTED,
  FETCH_DEVICES_SUCCEEDED,
  SET_DISPLAYED_CHANNELS,
  ADD_DISPLAYED_CHANNEL,
  ADD_SELECTED_CHANNEL,
  DELETE_SELECTED_CHANNEL,
  DELETE_DISPLAYED_CHANNEL,
  DELETE_VISUALIZATION_CHANNEL,
  SET_VISUALIZATION_CHANNELS,
  ADD_VISUALIZATION_CHANNEL,
  CLEAR_SELECTED_CHANNELS,
  SET_SELECTED_CHANNELS,
  UPDATE_SELECTED_CHANNEL,
  SET_DEFAULTCHANNEL_CHARTTYPE,
  SET_DEFAULTCHANNEL_AGGREGATIONTYPE,
  IS_CLEARING_CHANNEL_DIALOG
} from './selectChannelDialogActions.js';
import { fromJS } from 'immutable';

export const dialog = createReducer(null, {
  [SHOW_DIALOG](state) {
    return { ...state, open: true };
  },
  [HIDE_DIALOG](state) {
    return { ...state, open: false };
  }
});

export const fetchLocationsReducer = createReducer(null, {
  [FETCH_LOCATIONS_REQUESTED](state, { requesting }) {
    return { ...state, requesting: requesting };
  }
});

export const isFetchingChannelDialogLocations = createReducer(null, {
  [IS_FETCHING_CHANNEL_DIALOG_LOCATIONS](
    state,
    { isFetchingChannelDialogLocations }
  ) {
    return fromJS(isFetchingChannelDialogLocations);
  }
});

export const isFetchingChannelDialogDevices = createReducer(null, {
  [IS_FETCHING_CHANNEL_DIALOG_DEVICES](
    state,
    { isFetchingChannelDialogDevices }
  ) {
    return fromJS(isFetchingChannelDialogDevices);
  }
});

export const hasFinishedFetchingLocationsAndDevices = createReducer(null, {
  [HAS_FINISHED_FETCHING_LOCATIONS_AND_DEVICES](
    state,
    { hasFinishedFetchingLocationsAndDevices }
  ) {
    return fromJS(hasFinishedFetchingLocationsAndDevices);
  }
});

export const hasStartedFetchingLocationsAndDevices = createReducer(null, {
  [HAS_STARTED_FETCHING_LOCATIONS_AND_DEVICES](
    state,
    { hasStartedFetchingLocationsAndDevices }
  ) {
    return fromJS(hasStartedFetchingLocationsAndDevices);
  }
});

export const fetchDevicesReducer = createReducer(null, {
  [FETCH_DEVICES_REQUESTED](state, { requesting }) {
    return { ...state, requesting: requesting };
  },
  [FETCH_DEVICES_SUCCEEDED](state, { devices }) {
    return { ...state, devices: devices, requesting: false };
  }
});

export const visualizationChannels = createReducer(null, {
  [SET_VISUALIZATION_CHANNELS](state, { channels }) {
    return fromJS(channels);
  },
  [ADD_VISUALIZATION_CHANNEL](state, { channel }) {
    return state.push(channel);
  },
  [DELETE_VISUALIZATION_CHANNEL](state, { channel }) {
    return state.filter(c => c.get('id') !== channel.id);
  }
});

export const selectedChannels = createReducer(null, {
  [ADD_SELECTED_CHANNEL](state, { channel }) {
    return state.push({
      ...channel,
      aggregation:
        channel.aggregationType === 'source'
          ? 'source'
          : channel.aggregationType.split('-')[1],
      aggregationTime:
        channel.aggregationType === 'source'
          ? 'hour'
          : channel.aggregationType.split('-')[0],
      chartPane: 1
    });
  },
  [DELETE_SELECTED_CHANNEL](state, { channel }) {
    return fromJS(state.toJS().filter(c => c.id !== channel.id));
  },
  [SET_SELECTED_CHANNELS](state, { channels }) {
    return fromJS(
      channels.map(channel => ({
        ...channel,
        aggregation:
          channel.aggregationType === 'source'
            ? 'source'
            : channel.aggregationType.split('-')[1],
        aggregationTime:
          channel.aggregationType === 'source'
            ? 'hour'
            : channel.aggregationType.split('-')[0]
      }))
    );
  },
  [UPDATE_SELECTED_CHANNEL](state, { channel }) {
    const index = state.toJS().findIndex(c => c.id === channel.id);

    return state.update(index, () => {
      return {
        ...channel,
        aggregationType:
          channel.aggregation === 'source'
            ? 'source'
            : `${channel.aggregationTime}-${channel.aggregation}`,
        aggregationTime:
          channel.aggregation === 'source' ? 'hour' : channel.aggregationTime
      };
    });
  },
  [CLEAR_SELECTED_CHANNELS]() {
    return fromJS([]);
  }
});

export const isClearingChannelDialog = createReducer(null, {
  [IS_CLEARING_CHANNEL_DIALOG](state, { isClearingChannelDialog }) {
    return fromJS(isClearingChannelDialog);
  }
});

export const displayedChannels = createReducer(null, {
  [SET_DISPLAYED_CHANNELS](state, { channels }) {
    return fromJS(channels);
  },
  [ADD_DISPLAYED_CHANNEL](state, { channel }) {
    return state.push(channel);
  },
  [DELETE_DISPLAYED_CHANNEL](state, { channel }) {
    return state.filter(c => c.get('id') !== channel.id);
  }
});

export const defaultChannelChartType = createReducer(null, {
  [SET_DEFAULTCHANNEL_CHARTTYPE](state, { defaultChannelChartType }) {
    return fromJS(defaultChannelChartType);
  }
});

export const defaultChannelAggregationType = createReducer(null, {
  [SET_DEFAULTCHANNEL_AGGREGATIONTYPE](
    state,
    { defaultChannelAggregationType }
  ) {
    return fromJS(defaultChannelAggregationType);
  }
});
