import React, { Component } from 'react';
import { Auth0Context } from '../../react-auth0-spa';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { PropTypes } from 'prop-types';

export class Page extends Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);

    const drawerExpanded =
      localStorage.getItem('drawerExpanded') === 'true' ||
      localStorage.getItem('drawerExpanded') === null;
    this.state = {
      drawerExpanded
    };
  }

  handleItemClick() {
    const isMobile = window.matchMedia('(max-width: 800px)').matches;
    if (!isMobile) return;
    this.handleDrawer();
  }

  handleDrawer() {
    const drawerExpanded = !this.state.drawerExpanded;
    localStorage.setItem('drawerExpanded', drawerExpanded);
    this.setState({ drawerExpanded });
  }

  getDrawer() {
    return this.state.drawerExpanded;
  }

  render() {
    return (
      <div className="root">
        <TopBar toggleDrawer={() => this.handleDrawer()} />
        <NavBar
          content={this.props.children}
          title={this.props}
          drawerExpanded={this.state.drawerExpanded}
          handleDrawer={() => this.handleDrawer()}
          handleItemClick={event => this.handleItemClick(event)}
        />
      </div>
    );
  }
}

Page.propTypes = {
  children: PropTypes.any.isRequired
};
