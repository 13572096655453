import React, { useEffect, useState } from 'react';
import Content from '../../page/Content';
import makeStyles from '@mui/styles/makeStyles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { ManageLocationContainer } from './manageLocation/ManageLocationContainer';
import { VisualizeContainer } from '../visualize/VisualizeContainer';
import { LogItemsDataTableContainer } from './logbook/LogItemsDataTableContainer';
import { ManualInsertContainer } from './manualInsert/ManualInsertContainer';
import DevextremeMap from 'devextreme-react/map';
import ImdButton from 'components/imd-components/ImdButton';
import MapIcon from '@mui/icons-material/Map';
import { isEmptyObject } from 'utils/helperMethods';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { getMarker } from 'utils/mapHelpers';
import { usePrevious, useEffectOnce } from 'react-use';
import { history } from '../../../utils/configureStore';
import configFile from '../../../App_config.json';

export function LocationDisplay({
  locationId,
  navigationItem,
  location,
  fetchLocation,
  addSelectedLocation,
  setVisualizeFromTime,
  setCurrentVisualizePeriod,
  setDefaultChannelChartType,
  setDefaultChannelAggregationType,
  hasStartedFetchingLocationsAndDevices,
  hasFinishedFetchingLocationsAndDevices
}) {
  const classes = useStyles();
  const wasStartedFetchingLocationsAndDevices = usePrevious(
    hasStartedFetchingLocationsAndDevices
  );
  const link = `/management/locations/${locationId}`;
  const { t } = useTranslation();

  const navigationItems = [
    {
      id: 0,
      name: t('content.managelocation.visualize'),
      path: 'visualize',
      active: true
    },
    {
      id: 1,
      name: t('content.managelocation.details'),
      path: 'details',
      active: false
    },
    {
      id: 2,
      name: t('content.managelocation.map'),
      path: 'map',
      active: false
    },
    {
      id: 3,
      name: t('content.managelocation.logbook'),
      path: 'logbook',
      active: false
    },
    {
      id: 4,
      name: t('content.managelocation.manualinsert'),
      path: 'manualinsert',
      active: false
    }
  ];

  const [navigation, setNavigation] = useState(
    navigationItems.find(ni => ni.path === navigationItem)?.id || 0
  );

  const navigate = value => {
    history.push(
      `${link}/${navigationItems.find(ni => ni.id === value)?.path}`
    );
    setNavigation(value);
  };

  const cardContent = (
    <div>
      <DevextremeMap
        defaultZoom={17}
        width="100%"
        height="75vh"
        controls={true}
        provider="bing"
        apiKey={configFile.bingMapsAPIKey}
      >
        {location.latLngLatitude ? getMarker(location) : null}
      </DevextremeMap>
    </div>
  );

  useEffectOnce(() => {
    if (locationId) {
      fetchLocation(locationId);
    }
    setVisualizeFromTime(new Date(new Date().getTime() - 12 * 60 * 60 * 1000));
    setCurrentVisualizePeriod('twentyFourHours');
    setDefaultChannelChartType('line');
    setDefaultChannelAggregationType('source');
  }, []);

  useEffect(() => {
    // Wanneer de locaties en apparaten zijn opgehaald en de locatie nog niet is toegevoegd (voorkomt dat locaties worden toegevoegd bij component render);
    if (
      hasFinishedFetchingLocationsAndDevices &&
      wasStartedFetchingLocationsAndDevices &&
      location.sensightId === locationId
    ) {
      addSelectedLocation(location);
    }
  }, [hasFinishedFetchingLocationsAndDevices, location]);

  const renderCardContent = () => {
    switch (navigation) {
      case 0:
        return (
          <VisualizeContainer
            clearSelectChannelDialogStateImmediately
            width="100%"
          />
        );
      case 1:
        return <ManageLocationContainer locationId={location.id} />;
      case 2:
        return cardContent;
      case 3:
        return (
          <LogItemsDataTableContainer
            location={location}
            measurementLocationId={location.measurementServiceLocation?.id}
            locationSensightId={location.sensightId}
          />
        );
      case 4:
        return <ManualInsertContainer />;
      default:
        return null;
    }
  };

  const renderListItems = () => {
    return navigationItems.map(item => {
      return (
        <ListItem
          divider
          onClick={() => navigate(item.id)}
          key={item.id}
          className={classes.button}
        >
          <ListItemText
            classes={{
              primary: classes.listItemText
            }}
            primary={renderName(item.id, item.name)}
          />
        </ListItem>
      );
    });
  };

  const renderName = (id, name) => {
    if (navigation == id) {
      return <b>{name}</b>;
    }
    return name;
  };

  const navLink = `https://maps.google.com/?q=${location.latLngLatitude},${location.latLngLongitude}`;

  const content = (
    <>
      <div className={classes.headerDiv}>
        <h3 className={classes.header}>{location.name}</h3>
        <a
          href={navLink}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.tableCell}
        >
          <ImdButton className={classes.routeButton} startIcon={<MapIcon />}>
            {t('content.managelocation.route')}
          </ImdButton>
        </a>
      </div>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xl={1} lg={2} md={2} sm={12} xs={12}>
          <div className={classes.sideNavigation}>
            <Divider />
            {renderListItems()}
          </div>
        </Grid>
        <Grid item xl={11} lg={10} md={10} sm={12} xs={12}>
          <div className={classes.contentCard}>{renderCardContent()}</div>
        </Grid>
      </Grid>
    </>
  );

  if (isEmptyObject(location) || location.sensightId !== locationId) {
    return (
      <div className={classes.spinnerWrapper}>
        <CircularProgress className={classes.spinner} />
      </div>
    );
  }
  return (
    <Content title={t('content.managelocation.title')} content={content} />
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh'
  },
  headerDiv: {
    display: 'flex',
    flexDirection: 'row'
  },
  header: {
    marginTop: '0px',
    marginBottom: '16px',
    width: '100%'
  },
  routeButton: {
    alignSelf: 'end',
    marginBottom: '4px'
  },
  contentCard: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)'
  },
  sideNavigation: {
    fontSize: '0.8rem'
  },
  listItemText: {
    fontSize: '0.9rem'
  },
  tabs: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)'
  },
  tabContent: {
    marginTop: '16px',
    marginLeft: '8px'
  },
  submitButton: {
    marginTop: '8px'
  },
  spinnerWrapper: {
    height: '75vh',
    display: 'flex',
    justifyContent: 'center'
  },
  spinner: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.secondary.contrastText,
    paddingLeft: '4px',
    paddingRight: '0px'
  }
}));
