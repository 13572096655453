import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ImdButton from 'components/imd-components/ImdButton';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Switch } from '@mui/material';
import clsx from 'clsx';

export function DisplayChannelOptionsDialogDisplay({
  chartRendered,
  setChartDisplayOptions,
  displayChart,
  dialogOpen,
  setDialogOpen,
  chartDisplayOptions
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDialogClose = () => {
    if (chartRendered) {
      displayChart();
    }
    setDialogOpen(false);
  };

  const handleSwitchChange = name => event => {
    const value = { ...chartDisplayOptions, [name]: event.target.checked };
    setChartDisplayOptions(value);
  };

  const SwitchItem = props => {
    return (
      <div className={clsx(classes.switchWrapper, classes.firstWrapper)}>
        <Typography variant="body1" className={classes.switchLabel}>
          {props.name}
        </Typography>
        <div className={classes.switchItem}>
          <Switch
            checked={chartDisplayOptions[props.variableToSwitch]}
            onChange={handleSwitchChange(props.variableToSwitch)}
            value="hide"
            color="primary"
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={dialogOpen}
      onClose={() => handleDialogClose()}
      scroll={'paper'}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title" className={classes.dialogTitle}>
        {t('content.visualize.toolbar.displaysettings.title')}
      </DialogTitle>
      <DialogContent>
        <SwitchItem
          name={t('content.visualize.toolbar.displaysettings.renderGrid')}
          variableToSwitch={'renderGrid'}
        />
        <SwitchItem
          name={t('content.visualize.toolbar.displaysettings.fixedYAxis')}
          variableToSwitch={'fixedYAxis'}
        />
        <SwitchItem
          name={t('content.visualize.toolbar.displaysettings.renderLimitLines')}
          variableToSwitch={'renderLimitLines'}
        />
        <SwitchItem
          name={t('content.visualize.toolbar.displaysettings.renderLogItems')}
          variableToSwitch={'renderLogItems'}
        />
        <SwitchItem
          name={t('content.visualize.toolbar.displaysettings.autoRefresh')}
          variableToSwitch={'autoRefresh'}
        />
      </DialogContent>
      <DialogActions>
        <ImdButton
          onClick={() => handleDialogClose()}
          variant="contained"
          color="primary"
        >
          {t('content.visualize.dialog.actions.button.save')}
        </ImdButton>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  switchWrapper: {
    display: 'flex'
  },
  switchLabel: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  switchItem: {
    marginLeft: 'auto'
  }
});
