import { TinyColor } from '@ctrl/tinycolor';
import {
  getVisualisationByGuidAsync,
  createVisualisationAsync
} from 'apis/VisualisationApi';
import { getMeasurementChannelBySensightId } from 'apis/MeasurementServiceApi';
import { createSnackbar } from 'redux/ui/uiActions';

export const visualizationTypes = {
  chart: 1,
  1: 'chart',
  kpi: 2,
  2: 'kpi',
  markdown: 3,
  3: 'markdown',
  image: 4,
  4: 'image',
  dataScience: 5,
  5: 'dataScience',
  logbookItems: 6,
  6: 'logbookItems'
};

// methode maken voor het uitlezen van de link en het opaheln van de extra gegevens

export async function generateVisualisationLink(user, visualisationSettings) {
  const visualisationProperties = {
    selectionDataParameter: {
      visualizeFromTime: new Date(
        visualisationSettings.selectionDataParameter.visualizeFromTime
      ),
      visualizeUntilTime: new Date(
        visualisationSettings.selectionDataParameter.visualizeUntilTime
      ),
      visualizePeriod:
        visualisationSettings.selectionDataParameter.visualizePeriod
    },
    selectedChannels: visualisationSettings.selectedChannels.map(channel => ({
      channelSensightId: channel.sensightId,
      chartTypeName: channel.chartTypeName,
      color: channel.color,
      name: channel.name,
      value: channel.value,
      aggregationType: channel.aggregationType,
      channelId: channel.channelId,
      chartPane: channel.chartPane
    })),
    displayOption: {
      fixedYAxis: visualisationSettings.displayOption.fixedYAxis,
      autoRefresh: visualisationSettings.displayOption.autoRefresh,
      renderLogItems: visualisationSettings.displayOption.renderLogItems,
      renderLimitLines: visualisationSettings.displayOption.renderLimitLines,
      renderGrid: visualisationSettings.displayOption.renderGrid
    }
  };

  console.dev(
    `Generating base64 encoded export object from settings:`,
    visualisationProperties
  );

  // Aanroep voor het genereren van de link (nieuw)
  return await createVisualisationAsync(user, visualisationProperties);
}

export async function readVisualisationLink(user, visualisationLink) {
  const guidPattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  if (visualisationLink) {
    // Controleren of de link een guid bevat.
    if (guidPattern.test(visualisationLink)) {
      const result = await getVisualisationByGuidAsync(user, visualisationLink);
      const visualizationData = {
        selectionData: result.data.selectionDataParameter,
        displayOptions: result.data.displayOption,
        selectedChannels: []
      };

      // Missende channel data ophalen
      for (const channel of result.data.selectedChannels) {
        const retrievedChannel = await getMeasurementChannelBySensightId(
          user,
          channel.channelSensightId
        );

        // Ook de originele data nog even meegeven
        retrievedChannel.data[0].name = `${retrievedChannel.externalChannelId} [${retrievedChannel.unit}]`;
        retrievedChannel.data[0].channelId = channel.id;
        retrievedChannel.data[0].color = channel.color;
        retrievedChannel.data[0].value = channel.value;
        retrievedChannel.data[0].chartTypeName = channel.chartTypeName;
        retrievedChannel.data[0].aggregationType = channel.aggregationType;
        retrievedChannel.data[0].chartPane = channel.chartPane;

        // Verrijkte data doorzetten naar het uiteindelijke model
        visualizationData.selectedChannels.push(retrievedChannel.data[0]);
      }

      return visualizationData;
    }
    // Oude methode moet ook supported blijven
    else {
      try {
        return deserializeFromBase64(visualisationLink);
      } catch {
        createSnackbar('content.visualize.toolbar.link.error', 'error');
      }
    }
  }
}

export function cloneObject(object) {
  return JSON.parse(JSON.stringify(object));
}

export function cloneArray(array) {
  return array.slice(0);
}

export function isEmptyObject(obj) {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export function deserializeFromBase64(visualizeLink) {
  return JSON.parse(atob(visualizeLink));
}

export function moveItemPositionInArray(array, from, to) {
  return array.map((item, i) =>
    i === to
      ? array[from]
      : i >= Math.min(from, to) && i <= Math.max(from, to)
      ? array[i + Math.sign(to - from)]
      : item
  );
}

export function serializeToBase64(data) {
  return btoa(JSON.stringify(data));
}

export function generateVisualizeLink(data) {
  return `${window.location.origin}/visualize/${serializeToBase64(data)}`;
}

export function calculateColumnWidth(numberOfColumns) {
  return 12 / numberOfColumns;
}

export function getDateFromTimeString(time) {
  let date = new Date();
  date.setHours(0, 0, 0);
  if (time) {
    const timeValues = time.split(':');
    date.setHours(timeValues[0], timeValues[1], timeValues[2]);
  }
  return date;
}

export function getODataFormattedDateString(date) {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() + offset * 60 * 1000);
  return date.toISOString().split('T')[0];
}

export function getODataFormattedDateTimeString(date) {
  date = new Date(date.getTime());
  return date.toISOString();
}

export function removeSelectedEntriesFromOriginalList(
  originalList,
  selectedList
) {
  for (let i = 0; i < originalList.length; i++) {
    const device = originalList[i];

    for (let j = 0; j < selectedList.length; j++) {
      const selectedDevice = selectedList[j];
      if (device.id === selectedDevice.id) {
        originalList.splice(i, 1);
        break;
      }
    }
  }

  return originalList;
}

export function getAggregationValue(aggregationValue) {
  switch (aggregationValue) {
    case 'source':
      return 'value';
    case 'avg':
      return 'valueAvg';
    case 'sum':
      return 'valueSum';
    case 'min':
      return 'valueMin';
    case 'max':
      return 'valueMax';
    default:
      return 'value';
  }
}

export function deepEqual(object1, object2) {
  return JSON.stringify(object1) === JSON.stringify(object2);
}

export function objectKeysAreEqual(o1 = {}, o2 = {}) {
  return deepEqual(Object.keys(o1), Object.keys(o2));
}

export function getEmptyGuid() {
  return '00000000-0000-0000-0000-000000000000';
}

export function generateRandomHexCode() {
  return '#000000'.replace(/0/g, () => {
    return (~~(Math.random() * 16)).toString(16);
  });
}

export const zeroPad = (num, places) => String(num).padStart(places, '0');

/**
 * Kleurenshades generator
 * @param {*} fromColor de kleur waarvan verschillende tinten moeten worden gemaakt
 * @param {*} shades  het aantal tinten dat moet worden gemaakt
 * @param {*} alsoReturnFromColor de meegegeven kleur ook als eerste waarde in de array teruggeven
 * Om snel te finetunen met kleuren zie de bijbehorende JSFIDDLE: https://jsfiddle.net/xub04req/
 */
export function generateShadesOfColor(
  fromColor,
  shades,
  alsoReturnFromColor = false
) {
  const color = new TinyColor(fromColor);
  const ColorIsDark = color.isDark();
  const shadeDifference = shades === 1 ? 15 : 30;
  const colors = [];

  for (let i = 0; i < shades; i += 1) {
    colors.push(
      (ColorIsDark
        ? color.lighten((i + 1) * (shadeDifference / shades))
        : color.darken((i + 1) * (shadeDifference / shades))
      ).toHexString()
    );
  }

  //Tijdelijk deze regels laten staan om gemakkelijk te kunnen finetunen
  //console.log(`Start met maken van ${shades} tinten voor de kleur ${fromColor}`);
  //console.log(`%c Originele ${ColorIsDark ? `donkere` : `lichte`} kleur! `, `background: ${fromColor}; color: #bada55`, fromColor)
  //colors.forEach(hex => console.log(`%c Test kleur! `, `background: ${hex}; color: #bada55`, hex));

  if (alsoReturnFromColor) {
    colors.unshift(fromColor);
  }

  return colors;
}
