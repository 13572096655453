import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { PropTypes } from 'prop-types';
import DevextremeDataGrid, {
  Column,
  Editing,
  Selection,
  Lookup
} from 'devextreme-react/data-grid';
import { useTranslation } from 'react-i18next';
import ImdColorPicker from 'components/imd-components/ImdColorPicker';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormGroup
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { defaultChannelAggregationTimeTypeSelector } from '../toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogSelectors';
import { setDefaultChannelAggregationType } from '../toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogActions';

export function ChannelDataTable({
  selectedChannels,
  onRowUpdated,
  deleteAction,
  defaultAggregation,
  chartSettings,
  chartAggregation,
  chartType
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  function selectionChanged(data) {
    this.setState({
      selectedItemKeys: data.selectedRowKeys
    });
  }

  const aggregations = [
    {
      aggregationType: 'source',
      name: 'Bronwaarden'
    },
    {
      aggregationType: 'avg',
      name: 'Gemiddelde'
    },
    {
      aggregationType: 'min',
      name: 'Minimale'
    },
    {
      aggregationType: 'max',
      name: 'Maximale'
    },
    {
      aggregationType: 'sum',
      name: 'Som'
    }
  ];

  const aggregationTimes = [
    {
      aggregationTime: '5minute',
      name: '5 Minuten'
    },
    {
      aggregationTime: '10minute',
      name: '10 Minuten'
    },
    {
      aggregationTime: '15minute',
      name: '15 Minuten'
    },
    {
      aggregationTime: 'hour',
      name: 'Uur'
    },
    {
      aggregationTime: 'day',
      name: 'Dag'
    },
    {
      aggregationTime: 'week',
      name: 'Week'
    },
    {
      aggregationTime: 'month',
      name: 'Maand'
    }
  ];

  const chartTypes = [
    {
      chartTypeName: 'line',
      displayLabel: t(`content.dashboard.visualizationtypes.charttype.line`)
    },
    {
      chartTypeName: 'stepline',
      displayLabel: t(`content.dashboard.visualizationtypes.charttype.stepline`)
    },
    {
      chartTypeName: 'spline',
      displayLabel: t(`content.dashboard.visualizationtypes.charttype.spline`)
    },
    {
      chartTypeName: 'scatter',
      displayLabel: t(`content.dashboard.visualizationtypes.charttype.scatter`)
    },
    {
      chartTypeName: 'bar',
      displayLabel: t(`content.dashboard.visualizationtypes.charttype.bar`)
    },
    {
      chartTypeName: 'area',
      displayLabel: t(`content.dashboard.visualizationtypes.charttype.area`)
    },
    {
      chartTypeName: 'steparea',
      displayLabel: t(`content.dashboard.visualizationtypes.charttype.steparea`)
    },
    {
      chartTypeName: 'kpi',
      displayLabel: t(`content.dashboard.visualizationtypes.charttype.kpi`)
    }
  ];

  const chartPanes = Array.from({ length: 6 }, (_, i) => ({
    chartPane: i + 1,
    displayLabel: `${t(`content.dashboard.visualizationtypes.charttitle`)} ${
      i + 1
    }`
  }));

  const onColorChanged = (rowIndex, color) => {
    selectedChannels[rowIndex].color = color;
    onRowUpdated({ data: selectedChannels[rowIndex] });
  };

  const [globalAggregationType, setGlobalAggregationType] = useState('avg');
  const [globalAggregationTime, setGlobalAggregationTime] = useState('hour');

  const defaultChannelType = useSelector(state =>
    defaultChannelAggregationTimeTypeSelector(state)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const stringArray = defaultChannelType.split('-');

    if (stringArray.length === 1) {
      setGlobalAggregationType(
        aggregations.find(aggType => aggType.aggregationType === stringArray[0])
          .aggregationType
      );
    } else {
      setGlobalAggregationType(
        aggregations.find(aggType => aggType.aggregationType === stringArray[1])
          .aggregationType
      );

      setGlobalAggregationTime(
        aggregationTimes.find(
          aggTime => aggTime.aggregationTime === stringArray[0]
        ).aggregationTime
      );
    }
  }, [defaultChannelType]);

  function cellRender(data) {
    return (
      <ImdColorPicker
        color={data.value}
        rowIndex={data.rowIndex}
        handleChangeComplete={onColorChanged}
      />
    );
  }

  const editGlobalAggregationType = value => {
    if (value === 'source') {
      dispatch(setDefaultChannelAggregationType(value));
    } else {
      dispatch(
        setDefaultChannelAggregationType(globalAggregationTime + '-' + value)
      );
    }
    setGlobalAggregationType(value);
  };

  const editGlobalAggregationTime = value => {
    dispatch(
      setDefaultChannelAggregationType(value + '-' + globalAggregationType)
    );
    setGlobalAggregationTime(value);
  };

  const aggregationMapTypes = aggregations.map(agg => (
    <MenuItem key={agg.aggregationType} value={agg.aggregationType}>
      {t(`content.general.aggregationtype.${agg.aggregationType}`)}
    </MenuItem>
  ));

  const aggregationMapTimes = aggregationTimes.map(agg => (
    <MenuItem key={agg.aggregationTime} value={agg.aggregationTime}>
      {t(`content.general.aggregationtype.${agg.aggregationTime}`)}
    </MenuItem>
  ));

  const onEditorPreparing = e => {
    if (e.dataField === 'aggregationTime') {
      const isDisabled = e.row.data.aggregation === 'source';
      e.editorOptions.disabled = isDisabled;
    }
  };

  return (
    <>
      {defaultAggregation ? (
        <FormGroup aria-label="position" className={classes.formGroup} row>
          <div className={classes.label}>
            {t('content.channelsselector.defaultaggregation')}
          </div>
          <FormControl className={classes.formControl}>
            <InputLabel variant="standard" id="demo-simple-select-label">
              {t(
                'content.channelsselector.defaultaggregation.column.aggregation'
              )}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={globalAggregationType}
              onChange={value => editGlobalAggregationType(value.target.value)}
              variant="standard"
            >
              {aggregationMapTypes}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel variant="standard" id="demo-simple-select-label">
              {t('content.channelsselector.defaultaggregation.column.time')}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={globalAggregationTime}
              disabled={globalAggregationType === 'source'}
              onChange={value => editGlobalAggregationTime(value.target.value)}
              variant="standard"
            >
              {aggregationMapTimes}
            </Select>
          </FormControl>
        </FormGroup>
      ) : null}
      <DevextremeDataGrid
        id="gridContainer"
        className={classes.dataGrid}
        dataSource={selectedChannels}
        keyExpr="id"
        showBorders={true}
        columnAutoWidth={true}
        onSelectionChanged={selectionChanged}
        onEditorPreparing={onEditorPreparing}
        onRowUpdated={onRowUpdated}
        activeStateEnabled
      >
        <Selection mode="none" />
        <Editing mode="cell" allowUpdating={true} />
        <Column
          dataField="displayLabel"
          caption={t('content.visualize.dialog.table.column.name.text')}
          width={'55%'}
          allowEditing={false}
        />
        {chartSettings ? (
          <Column
            dataField="color"
            caption={t('content.visualize.dialog.table.column.color.text')}
            allowSorting={false}
            allowEditing={false}
            cellRender={cellRender}
            width={'5%'}
          />
        ) : null}
        {chartSettings || chartAggregation ? (
          <Column
            dataField="aggregation"
            caption={t(
              'content.visualize.dialog.table.column.aggregation.text'
            )}
            showEditorAlways
            width={'10%'}
          >
            <Lookup
              dataSource={aggregations}
              valueExpr="aggregationType"
              displayExpr={item =>
                t(`content.general.aggregationtype.${item.aggregationType}`)
              }
            />
          </Column>
        ) : null}
        {chartSettings || chartAggregation ? (
          <Column
            dataField="aggregationTime"
            caption={t('content.visualize.dialog.table.column.time.text')}
            showEditorAlways
            width={'8%'}
          >
            <Lookup
              dataSource={aggregationTimes}
              valueExpr="aggregationTime"
              displayExpr={item =>
                t(`content.general.aggregationtype.${item.aggregationTime}`)
              }
            />
          </Column>
        ) : null}
        {chartSettings && chartType ? (
          <Column
            dataField="chartTypeName"
            caption={t(
              'content.visualize.dialog.table.column.visualisationType.text'
            )}
            showEditorAlways
          >
            <Lookup
              dataSource={chartTypes}
              valueExpr="chartTypeName"
              displayExpr="displayLabel"
            />
          </Column>
        ) : null}
        <Column
          dataField="chartPane"
          caption={t('content.visualize.dialog.table.column.chart.text')}
          showEditorAlways
          width={'8%'}
        >
          <Lookup
            dataSource={chartPanes}
            valueExpr="chartPane"
            displayExpr="displayLabel"
          />
        </Column>
        <Column
          caption={t('content.visualize.dialog.table.column.button.deselect')}
          allowEditing={false}
          type="buttons"
          buttons={[
            'delete',
            {
              hint: t('content.visualize.dialog.table.column.button.deselect'),
              icon: 'trash',
              onClick: event => deleteAction(event.row.data)
            }
          ]}
          width={'8%'}
        />
      </DevextremeDataGrid>
    </>
  );
}

const useStyles = makeStyles({
  dataTable: {
    marginBottom: '16px',
    marginTop: '16px'
  },
  dataGrid: {
    marginTop: '16px',
    height: '300px'
  },
  formControl: {
    minWidth: 120,
    marginLeft: '15px'
  },
  label: {
    paddingTop: '20px'
  },
  formGroup: {}
});

ChannelDataTable.propTypes = {
  selectedChannels: PropTypes.array.isRequired,
  onRowUpdated: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  defaultAggregation: PropTypes.string,
  chartSettings: PropTypes.bool,
  chartAggregation: PropTypes.bool,
  chartType: PropTypes.string
};
