import { createSelector } from 'reselect';
export const locationDetailsSelector = createSelector(
  state => state.get('locationDetails'),
  locationDetails => locationDetails.toJS()
);

export const selectedOrganizationSelector = createSelector(
  state => state.get('autocompleteSelectedOrganizations'),
  autocompleteSelectedOrganizations => autocompleteSelectedOrganizations.toJS()
);

export const autocompleteSelectedServiceContactPersonsSelector = createSelector(
  state => state.get('autocompleteSelectedServiceContactPersons'),
  autocompleteSelectedServiceContactPersons =>
    autocompleteSelectedServiceContactPersons.toJS()
);

export const autocompleteSelectedContactPersonSelector = createSelector(
  state => state.get('autocompleteSelectedContactPersons'),
  autocompleteSelectedContactPersons =>
    autocompleteSelectedContactPersons.toJS()
);

export const autocompleteSelectedProjectManagersSelector = createSelector(
  state => state.get('autocompleteSelectedProjectManagers'),
  autocompleteSelectedProjectManagers =>
    autocompleteSelectedProjectManagers.toJS()
);

export const hideSwitchValueSelector = createSelector(
  state => state.get('hideSwitchValue'),
  hideSwitchValue => hideSwitchValue
);

export const onlineSwitchValueSelector = createSelector(
  state => state.get('onlineSwitchValue'),
  onlineSwitchValue => onlineSwitchValue
);
