import Drawer from '@mui/material/Drawer';
import { Typography, Collapse } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business';
import ChatIcon from '@mui/icons-material/Chat';
import DevicesIcon from '@mui/icons-material/Devices';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import SimCardIcon from '@mui/icons-material/SimCard';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SpeedIcon from '@mui/icons-material/Speed';
import ExploreIcon from '@mui/icons-material/Explore';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import PieChartIcon from '@mui/icons-material/PieChart';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import TuneIcon from '@mui/icons-material/Tune';
import PublicIcon from '@mui/icons-material/Public';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import { PropTypes } from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { NewDashboardDialogContainer } from 'components/content/dashboard/newDashboardDialog/NewDashboardDialogContainer';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import { useDispatch, useSelector } from 'react-redux';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { getCurrentUserRole } from 'utils/currentUserReader';
import { Can } from '../imd-components/authorization/Can';

export default function NavBar(props) {
  const classes = useNavBarStyles();
  const { t } = useTranslation();
  let location = useLocation().pathname;
  const dispatch = useDispatch();
  const currentUser = useSelector(state => currentUserSelector(state));

  const [selected, setSelected] = React.useState('');
  const [openManagement, setOpenManagement] = React.useState(false);
  const [openTenants, setOpenTenants] = React.useState(false);
  const [openManager, setOpenManager] = React.useState(false);
  const [openDashboard, setOpenDashboard] = React.useState(true);
  const [newDashboard, setNewDashboard] = React.useState(false);

  const isMobile = window.matchMedia('(max-width: 450px)').matches;

  const handleItemClick = () => {
    setOpenDashboard(false);
    props.handleItemClick();
  };

  const handleManagementClick = () => {
    setOpenDashboard(false);
    setOpenManagement(!openManagement);
  };

  const handleManagerClick = () => {
    setOpenDashboard(false);
    setOpenManager(!openManager);
  };

  const handleTenantsClick = () => {
    setOpenDashboard(false);
    setOpenTenants(!openTenants);
  };

  const handleDashboardClick = () => {
    setOpenDashboard(true);

    props.handleItemClick();
  };

  const handleNewDashboardClick = () => {
    setNewDashboard(true);
    props.handleItemClick();
  };

  const handleOk = () => {
    setNewDashboard(false);
  };

  useEffect(() => {
    setSelected(location);
    if (location.startsWith('/management')) {
      setOpenManagement(true);
    }
    if (location.startsWith('/manager')) {
      setOpenManager(true);
    }
  }, [location]);

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={props.drawerExpanded}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <List className={classes.navigationList}>
          <Can
            role={getCurrentUserRole(currentUser)}
            perform="dashboard:manage"
            yes={() => (
              <>
                <ListItemButton
                  onClick={handleDashboardClick}
                  component={Link}
                  to={'/'}
                  className={classes.button}
                  classes={{
                    selected: classes.MuiListItemSelected
                  }}
                  selected={selected === '/'}
                  key={'dashboard'}
                >
                  <ListItemIcon sx={{ color: 'inherit' }}>
                    <SpeedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('content.dashboard.title')} />
                  {openDashboard ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openDashboard} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    className={classes.nested}
                  >
                    <ListItemButton
                      component={Link}
                      classes={{
                        selected: classes.MuiListItemSelected
                      }}
                      selected={selected === '/dashboard/new'}
                      className={classes.button}
                      key={'manager.system'}
                      onClick={handleNewDashboardClick}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('content.dashboard.new.navbar')}
                      />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to={'/manage/dashboard'}
                      classes={{
                        selected: classes.MuiListItemSelected
                      }}
                      selected={selected === '/manage/dashboard'}
                      className={classes.button}
                      key={'manager.validation'}
                      onClick={props.handleItemClick}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <TuneIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('content.dashboard.manage.navbar')}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            )}
            no={() => (
              <ListItemButton
                className={classes.button}
                onClick={handleDashboardClick}
                component={Link}
                to={'/'}
                classes={{
                  selected: classes.MuiListItemSelected
                }}
                selected={selected === '/'}
                key={'dashboard'}
              >
                <ListItemIcon sx={{ color: 'inherit' }}>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={t('content.dashboard.title')} />
              </ListItemButton>
            )}
          />

          <ListItemButton
            component={Link}
            to={'/alerts'}
            classes={{
              selected: classes.MuiListItemSelected
            }}
            selected={selected === '/alerts'}
            className={classes.button}
            key={'alerts'}
            onClick={handleItemClick}
          >
            <ListItemIcon sx={{ color: 'inherit' }}>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText primary={t('content.alerts.title')} />
          </ListItemButton>
          {!isMobile ? (
            <>
              <ListItemButton
                component={Link}
                to={'/visualize'}
                classes={{
                  selected: classes.MuiListItemSelected
                }}
                selected={selected === '/visualize'}
                className={classes.button}
                key={'visualize'}
                onClick={handleItemClick}
              >
                <ListItemIcon sx={{ color: 'inherit' }}>
                  <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary={t('content.visualize.title')} />
              </ListItemButton>
              <ListItemButton
                component={Link}
                to={'/export'}
                classes={{
                  selected: classes.MuiListItemSelected
                }}
                selected={selected === '/export'}
                className={classes.button}
                key={'export'}
                onClick={handleItemClick}
              >
                <ListItemIcon sx={{ color: 'inherit' }}>
                  <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary={t('content.export.title')} />
              </ListItemButton>
            </>
          ) : null}
          <Can
            role={getCurrentUserRole(currentUser)}
            perform="route:management"
            yes={() => (
              <>
                <ListItemButton
                  className={classes.button}
                  onClick={handleManagementClick}
                >
                  <ListItemIcon sx={{ color: 'inherit' }}>
                    <DesktopWindowsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navbar.manage')} />
                  {openManagement ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openManagement} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    className={classes.nested}
                  >
                    <ListItemButton
                      component={Link}
                      to={'/management/contacts'}
                      classes={{
                        selected: classes.MuiListItemSelected
                      }}
                      selected={selected === '/management/contacts'}
                      className={classes.button}
                      key={'management.contacts'}
                      onClick={handleItemClick}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('content.management.contacts.title')}
                      />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to={'/management/locations'}
                      classes={{
                        selected: classes.MuiListItemSelected
                      }}
                      selected={selected === '/management/locations'}
                      className={classes.button}
                      key={'management.locations'}
                      onClick={handleItemClick}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <ExploreIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('content.management.locations.title')}
                      />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to={'/management/devices'}
                      classes={{
                        selected: classes.MuiListItemSelected
                      }}
                      selected={selected === '/management/devices'}
                      className={classes.button}
                      key={'management.devices'}
                      onClick={handleItemClick}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <DevicesIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('content.management.devices.title')}
                      />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to={'/management/alerts'}
                      classes={{
                        selected: classes.MuiListItemSelected
                      }}
                      selected={selected === '/management/alerts'}
                      className={classes.button}
                      key={'management.alerts'}
                      onClick={handleItemClick}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <ChatIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('content.management.alerts.title')}
                      />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to={'/management/export'}
                      classes={{
                        selected: classes.MuiListItemSelected
                      }}
                      selected={selected === '/management/export'}
                      className={classes.button}
                      key={'management.export'}
                      onClick={handleItemClick}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <PieChartIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('content.management.export.title')}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            )}
            no={() => null}
          />
          <Can
            role={getCurrentUserRole(currentUser)}
            perform="route:manager"
            yes={() => (
              <>
                <ListItemButton
                  className={classes.button}
                  onClick={handleManagerClick}
                >
                  <ListItemIcon sx={{ color: 'inherit' }}>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navbar.manager')} />
                  {openManager ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openManager} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    className={classes.nested}
                  >
                    <ListItemButton
                      component={Link}
                      to={'/manager/dashboards'}
                      classes={{
                        selected: classes.MuiListItemSelected
                      }}
                      selected={selected === '/manager/dashboards'}
                      className={classes.button}
                      key={'manager.dashboards'}
                      onClick={handleItemClick}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <SpeedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('content.manager.dashboards.title')}
                      />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to={'/manager/organizations'}
                      classes={{
                        selected: classes.MuiListItemSelected
                      }}
                      selected={selected === '/manager/organizations'}
                      className={classes.button}
                      key={'manager.organizations'}
                      onClick={handleItemClick}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <BusinessIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('content.manager.organizations.title')}
                      />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to={'/manager/simcards'}
                      classes={{
                        selected: classes.MuiListItemSelected
                      }}
                      selected={selected === '/manager/simcards'}
                      className={classes.button}
                      key={'manager.simcards'}
                      onClick={handleItemClick}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <SimCardIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('content.manager.simcards.title')}
                      />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to={'/manager/logbookactions'}
                      classes={{
                        selected: classes.MuiListItemSelected
                      }}
                      selected={selected === '/manager/logbookactions'}
                      className={classes.button}
                      key={'manager.logbookactions'}
                      onClick={handleItemClick}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <MenuBookIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('content.manager.logbookactions.title')}
                      />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to={'/manager/locationtypes'}
                      classes={{
                        selected: classes.MuiListItemSelected
                      }}
                      selected={selected === '/manager/locationtypes'}
                      className={classes.button}
                      key={'manager.locationtypes'}
                      onClick={handleItemClick}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <EditLocationIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('content.manager.locationtypes.title')}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            )}
            no={() => null}
          />
          <Can
            role={getCurrentUserRole(currentUser)}
            perform="route:platformadministrator"
            yes={() => (
              <>
                <ListItemButton
                  className={classes.button}
                  onClick={handleTenantsClick}
                >
                  <ListItemIcon sx={{ color: 'inherit' }}>
                    <SpaceDashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navbar.platform')} />
                  {openTenants ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openTenants} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    className={classes.nested}
                  >
                    <ListItemButton
                      component={Link}
                      to={'/manager/tenants'}
                      classes={{
                        selected: classes.MuiListItemSelected
                      }}
                      selected={selected === '/manager/tenants'}
                      className={classes.button}
                      key={'manager.themes'}
                      onClick={handleItemClick}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <PublicIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('content.tenants.title')} />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to={'/manager/themes'}
                      classes={{
                        selected: classes.MuiListItemSelected
                      }}
                      selected={selected === '/manager/themes'}
                      className={classes.button}
                      key={'manager.themes'}
                      onClick={handleItemClick}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <ColorLensIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('content.themes.title')} />
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            )}
            no={() => null}
          />
          <ListItemButton
            component={Link}
            to={'/profile'}
            classes={{
              selected: classes.MuiListItemSelected
            }}
            selected={selected === '/profile'}
            className={classes.button}
            key={'profile'}
            onClick={handleItemClick}
          >
            <ListItemIcon sx={{ color: 'inherit' }}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={t('content.profile.title')} />
          </ListItemButton>
        </List>
        <Typography variant="body2" className={classes.bottomButton}>
          Powered by <a href="https://imd-ma.nl/sensight">Sensight</a>
        </Typography>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: props.drawerExpanded
        })}
      >
        {props.content}
        <NewDashboardDialogContainer
          open={newDashboard}
          setSaveButtonDisabled={value =>
            dispatch(setSaveButtonDisabled(value))
          }
          setOpen={() => setNewDashboard(false)}
          handleOk={handleOk}
        />
      </main>
    </div>
  );
}

NavBar.propTypes = {
  drawerExpanded: PropTypes.any.isRequired,
  handleItemClick: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired
};

const drawerWidth = 200;

const useNavBarStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginLeft: drawerWidth,
    marginTop: '50px'
  },
  bottomButton: {
    position: 'fixed',
    paddingLeft: '8px',
    paddingBottom: '8px',
    bottom: 0,
    top: 'auto'
  },
  navigationList: {
    padding: 0
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 10,
    position: 'fixed'
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#eee',
    marginTop: '50px'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  MuiListItemSelected: {
    backgroundColor: theme.palette.primary.main + '!important',
    color: theme.palette.primary.contrastText + '!important'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    root: {
      display: 'flex'
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    }
  },
  button: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    cursor: 'pointer',
    color: theme.palette.secondary.contrastText
  }
}));
