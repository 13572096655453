import React, { useRef, useImperativeHandle } from 'react';
import DevextremeDataGrid, {
  Column,
  Editing
} from 'devextreme-react/data-grid';
import ImdCard from 'components/imd-components/ImdCard';
import { useTranslation } from 'react-i18next';

function ImdMetaDataForm(props, ref) {
  let metadataArray = [];
  if (props.metadata !== undefined && props.metadata !== null) {
    metadataArray = JSON.parse(props.metadata);
  }

  const { t } = useTranslation();

  const datagridRef = useRef();
  useImperativeHandle(ref, () => datagridRef);

  return (
    <ImdCard
      title={t('content.locationdashboard.generic.title')}
      content={
        <DevextremeDataGrid ref={datagridRef} dataSource={metadataArray}>
          <Editing
            mode="cell"
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
          />
          <Column
            dataField={'name'}
            caption={t('content.locationdashboard.generic.table.name')}
          />
          <Column
            dataField={'unit'}
            caption={t('content.locationdashboard.generic.table.unit')}
          />
          <Column
            dataField={'value'}
            caption={t('content.locationdashboard.generic.table.value')}
          />
        </DevextremeDataGrid>
      }
      variant="secondary"
    />
  );
}

export default React.forwardRef(ImdMetaDataForm);
