import {
  takeEvery,
  select,
  put,
  all,
  take,
  actionChannel
} from 'redux-saga/effects';
import {
  FETCH_LOCATION_DETAILS,
  setLocationDetails,
  setAutocompleteSelectedOrganizations,
  setAutocompleteSelectedServiceContactPersons,
  setAutocompleteSelectedContactPersons,
  UPDATE_LOCATION_DETAILS,
  UPDATE_PROJECT_DETAILS,
  setOnlineSwitchValue,
  setHideSwitchValue,
  RESET_LOCATION_DASHBOARD_STATE,
  setAutocompleteSelectedProjectManagers
} from './manageLocationActions';
import {
  getLocationById,
  getLocationOrganizationBySensightId,
  getLocationTypes,
  updateLocation,
  getServiceContacts,
  saveServiceContacts,
  saveOrganizationsOfLocation
} from 'apis/FieldServiceApi';
import { getContacts, getOrganizations } from 'apis/CustomerContactApi';
import {
  getLocationBySensightId,
  setLocationOnline,
  setLocationOffline,
  hideLocation,
  unhideLocation
} from 'apis/MeasurementServiceApi';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import { editLocationSucceeded } from 'components/content/locations/redux/locationsListActions';

export function* fetchLocationDetailsSaga() {
  yield takeEvery(FETCH_LOCATION_DETAILS, execute);
}

export function* resetLocationDashboardStateSaga() {
  yield takeEvery(RESET_LOCATION_DASHBOARD_STATE, resetDashboardLocationState);
}

export function* updateLocationSaga() {
  const locationDetailsChannel = yield actionChannel(UPDATE_LOCATION_DETAILS);
  const projectDetailsChannel = yield actionChannel(UPDATE_PROJECT_DETAILS);

  while (true) {
    const formValues = yield all([
      take(locationDetailsChannel),
      take(projectDetailsChannel)
    ]);

    const locationDetails = formValues[0].locationDetails;
    const projectDetails = formValues[1].projectDetails;

    const fieldServiceRequest = prepareFieldServiceRequest(
      locationDetails,
      projectDetails
    );

    const user = yield select(currentUserSelector);

    updateLocation(user, fieldServiceRequest);

    setLocationOnlineOrOffline(projectDetails, user, locationDetails);
    hideOrUnhideLocation(projectDetails, user, locationDetails);

    const organizationIds = projectDetails.organizationsCoupled.map(
      organization => organization.sensightId
    );

    saveOrganizationsOfLocation(
      user,
      locationDetails.sensightId,
      organizationIds
    );

    const serviceContactIds = projectDetails.serviceContactPerson.map(
      contact => contact.sensightId
    );
    saveServiceContacts(user, locationDetails.sensightId, serviceContactIds);

    yield put(createSnackbar(t('content.locationdashboard.form.success')));
    yield put(
      editLocationSucceeded({
        ...locationDetails,
        ...projectDetails
      })
    );
  }
}

function hideOrUnhideLocation(projectDetails, user, locationDetails) {
  if (projectDetails.visible) {
    unhideLocation(user, locationDetails.measurementId);
  } else {
    hideLocation(user, locationDetails.measurementId);
  }
}

function setLocationOnlineOrOffline(projectDetails, user, locationDetails) {
  if (projectDetails.online) {
    setLocationOnline(user, locationDetails.measurementId);
  } else {
    setLocationOffline(user, locationDetails.measurementId);
  }
}

function prepareFieldServiceRequest(locationDetails, projectDetails) {
  return {
    id: locationDetails.id,
    latLngLatitude: locationDetails.latLngLatitude,
    latLngLongitude: locationDetails.latLngLongitude,
    name: locationDetails.name,
    description: projectDetails.description,
    city: locationDetails.city,
    address: locationDetails.address,
    locationTypeId: locationDetails.locationType,
    referenceNumber: locationDetails.referenceNumber,
    projectNumber: locationDetails.projectNumber,
    projectManager: locationDetails.projectManager,
    contact: locationDetails.contact,
    metaData: locationDetails.metadata,
    switchtime: locationDetails.switchTime,
    timezone: locationDetails.timeZone
  };
}

function* resetDashboardLocationState() {
  yield put(
    setLocationDetails({
      location: {},
      organizationsCoupled: [],
      measurementServiceLocation: {
        online: false,
        visible: false
      },
      contacts: [],
      organizations: [],
      locationTypes: [],
      locationContact: {
        email: ''
      },
      listOfLocationServiceContacts: []
    })
  );
  yield put(setAutocompleteSelectedOrganizations([]));
  yield put(setAutocompleteSelectedServiceContactPersons([]));
  yield put(setAutocompleteSelectedContactPersons([]));
}

function* execute(action) {
  const user = yield select(currentUserSelector);

  let location = {};
  let locationServiceContacts = [];
  let locationContact = {};
  let locationProjectManager = {};
  let measurementServiceLocation = { visible: false, online: false };
  let locationOrganizations = [];

  try {
    const contacts = (yield getContacts(user)).data;
    const allOrganizations = (yield getOrganizations(user)).data;
    const locationTypes = (yield getLocationTypes(user)).data;

    if (action && action.locationId) {
      location = (yield getLocationById(user, action.locationId)).data;
      locationServiceContacts = (yield getServiceContacts(
        user,
        location.sensightId
      )).data;
      locationContact = findBySensightId(contacts, location.contact);
      locationProjectManager = findBySensightId(
        contacts,
        location.projectManager
      );

      measurementServiceLocation = (yield getLocationBySensightId(
        user,
        location.sensightId
      )).data[0];

      locationOrganizations = (yield getLocationOrganizationBySensightId(
        user,
        location.sensightId
      )).data;
    }

    const locationViewModel = composeLocationViewModel(
      location,
      contacts,
      locationOrganizations,
      allOrganizations,
      locationTypes,
      measurementServiceLocation,
      locationContact,
      locationProjectManager,
      locationServiceContacts
    );

    yield put(setAutocompleteSelectedOrganizations(locationOrganizations));
    yield put(
      setAutocompleteSelectedServiceContactPersons(locationServiceContacts)
    );
    yield put(setAutocompleteSelectedContactPersons(locationContact));
    yield put(setAutocompleteSelectedProjectManagers(locationProjectManager));
    yield put(setOnlineSwitchValue(measurementServiceLocation.online));
    yield put(setHideSwitchValue(measurementServiceLocation.visible));
    yield put(setLocationDetails(locationViewModel));
  } catch (e) {
    yield put(
      createSnackbar(t('content.locations.fetchlocationerror'), 'error')
    );
    console.log(e);
  }
}

function composeLocationViewModel(
  location,
  contacts,
  locationOrganizations,
  organizations,
  locationTypes,
  measurementServiceLocation,
  locationContact,
  locationProjectManager,
  listOfLocationServiceContacts
) {
  return {
    location: location,
    contacts: contacts,
    locationOrganizations: locationOrganizations,
    organizations: organizations,
    locationTypes: locationTypes,
    measurementServiceLocation: measurementServiceLocation,
    locationContact: locationContact,
    locationProjectManager: locationProjectManager,
    listOfLocationServiceContacts: listOfLocationServiceContacts
  };
}

function findBySensightId(contacts, sensightId) {
  return contacts.find(c => c.sensightId === sensightId);
}
