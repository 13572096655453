import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect
} from 'react';
import { MenuItem, FormControlLabel, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { TextField } from 'formik-mui';
import { makeStyles } from '@mui/styles';
import { getRoleMenuItems, getValueFromRole } from '../ContactHelpers';
import {
  getCurrentUserOrganization,
  getCurrentUserTenant
} from 'utils/currentUserReader';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { isEmptyObject } from 'utils/helperMethods';
import imdTimeZoneMenuItems from 'components/imd-components/imdTimeZoneMenuItems.jsx';

function CreateContactDisplay(
  {
    createContact,
    handleValidate,
    organizations,
    fetchOrganizationsList,
    currentUser,
    organization,
    setSaveButtonDisabled,
    setContactIsUser,
    contactIsUser,
    clearContactIsUser
  },
  ref
) {
  const { t } = useTranslation();
  const classes = useChangeProfileDetailsCardStyles();

  React.useEffect(() => {
    if (organizations.length === 0) {
      fetchOrganizationsList();
    }
    clearContactIsUser();
  }, []);

  const [contactDetails] = React.useState({
    name: '',
    email: '',
    phoneNumber: '',
    function: '',
    organizationid: organization || getCurrentUserOrganization(currentUser),
    role: 'Reader',
    status: 'active',
    tenant: getCurrentUserTenant(),
    timezone: 'Europe/Amsterdam',
    language: 'nl',
    username: ''
  });

  const handleSubmit = async (values, options) => {
    values.organization = organizations.find(
      org => org.id === values.organizationid
    );
    values.username = values.email.replace('@', '');
    values.contactIsUser = contactIsUser;
    values.role = getValueFromRole(values.role);
    createContact(values);
    options.setSubmitting(false);
  };

  const organizationMenuItems = organizations.map(organization => {
    return (
      <MenuItem key={organization.id} value={organization.id}>
        {organization.name}
      </MenuItem>
    );
  });

  const roleMenuItems = getRoleMenuItems();

  const handleUserCheckBox = value => {
    setContactIsUser(value);
  };

  const form = ({ submitForm, dirty, errors, validateForm }) => {
    useEffect(() => {
      (() => validateForm())();
    }, []);

    useEffect(() => {
      if (dirty && isEmptyObject(errors)) {
        setSaveButtonDisabled(false);
      } else {
        setSaveButtonDisabled(true);
      }
    }, [dirty, errors]);

    return (
      <Form onSubmit={submitForm}>
        <Field
          name="name"
          type="text"
          label={t('content.editcontact.input.name.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="phoneNumber"
          type="text"
          label={t('content.editcontact.input.phoneNumber.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="function"
          type="text"
          label={t('content.editcontact.input.function.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="email"
          type="email"
          label={t('content.editcontact.input.email.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="organizationid"
          label={t('content.editcontact.input.organization.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          type="select"
          select
          disabled={organization !== undefined}
          variant="standard"
        >
          {organizationMenuItems}
        </Field>
        <FormControlLabel
          control={
            <Field
              name="contactIsUser"
              color="primary"
              component={Switch}
              variant="standard"
            />
          }
          label={t('content.editcontact.input.user.text')}
          name="contactIsUser"
          onChange={(ev, checked) => {
            handleUserCheckBox(checked);
          }}
        />

        {contactIsUser ? (
          <>
            <Field
              name="language"
              type="select"
              label={t('content.editcontact.input.language.text')}
              fullWidth
              className={classes.textField}
              component={TextField}
              select
              variant="standard"
            >
              <MenuItem defaultValue value="nl">
                {t('content.profile.language.dutch')}
              </MenuItem>
              <MenuItem value="en">
                {t('content.profile.language.english')}
              </MenuItem>
              <MenuItem value="de">
                {t('content.profile.language.german')}
              </MenuItem>
              <MenuItem value="es">
                {t('content.profile.language.spanish')}
              </MenuItem>
              <MenuItem value="fi">
                {t('content.profile.language.finnish')}
              </MenuItem>
              <MenuItem value="pt">
                {t('content.profile.language.portuguese')}
              </MenuItem>
            </Field>
            <Field
              name="timezone"
              type="select"
              label={t('content.editcontact.input.timezone.text')}
              fullWidth
              className={classes.textField}
              component={TextField}
              select
              variant="standard"
            >
              {imdTimeZoneMenuItems}
            </Field>
            <Field
              name="status"
              type="select"
              label={t('content.editcontact.input.status.text')}
              fullWidth
              className={classes.textField}
              component={TextField}
              select
              variant="standard"
            >
              <MenuItem key="active" value="active">
                {t('content.editcontact.input.status.active')}
              </MenuItem>
              <MenuItem value="inactive">
                {t('content.editcontact.input.status.inactive')}
              </MenuItem>
            </Field>
            <Field
              name="role"
              type="select"
              label={t('content.editcontact.input.role.text')}
              fullWidth
              className={classes.textField}
              component={TextField}
              select
              variant="standard"
            >
              {roleMenuItems.menuItems}
            </Field>
          </>
        ) : (
          <></>
        )}
      </Form>
    );
  };

  const formRef = useRef();

  const inputFields = (
    <>
      <FormikWithRef
        ref={formRef}
        validateOnMount={true}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={contactDetails}
        validate={values => handleValidate(values)}
        onSubmit={(values, options) => {
          handleSubmit(values, options);
        }}
      >
        {form}
      </FormikWithRef>
    </>
  );
  useImperativeHandle(ref, () => formRef);

  return <>{inputFields}</>;
}

const useChangeProfileDetailsCardStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    margin: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
}));

export default forwardRef(CreateContactDisplay);
