import React, { useEffect} from 'react';  
import { useTranslation } from 'react-i18next';  
import { Formik, Field, Form } from 'formik';  
import ImdCard from '../../../imd-components/ImdCard';  
import ImdButton from '../../../imd-components/ImdButton';  
import { TextField } from 'formik-mui';  
import makeStyles from '@mui/styles/makeStyles';  
import MenuItem from '@mui/material/MenuItem';  
import { isEmptyObject } from 'utils/helperMethods';  
import { getActiveTenant } from 'utils/tenantManager';  
export function ChangeProfileDetailsDisplay({  
  profileDetails,  
  updateProfileDetails,  
  handleValidate,  
  fetchDashboards,  
  dashboards  
}) {  
  const { t } = useTranslation();  
  const classes = useChangeProfileDetailsCardStyles();  
  
   useEffect(() => {  
     fetchDashboards();  
   }, [fetchDashboards]);  
  
  const form = ({ submitForm, dirty }) => {  
    return (  
      <Form>  
        <Field  
          name="fullName"  
          type="text"  
          label={t(  
            'content.profile.changeprofiledetails.card.input.fullname.text'  
          )}  
          fullWidth  
          className={classes.textField}  
          component={TextField}  
          variant="standard"  
        />  
        <Field  
          name="email"  
          type="email"  
          label={t(  
            'content.profile.changeprofiledetails.card.input.email.text'  
          )}  
          fullWidth  
          className={classes.textField}  
          component={TextField}  
          variant="standard"  
        />  
        <Field  
          name="phonenumber"  
          type="text"  
          label={t(  
            'content.profile.changeprofiledetails.card.input.phonenumber.text'  
          )}  
          fullWidth  
          className={classes.textField}  
          component={TextField}  
          variant="standard"  
        Field/>  
        <Field  
          name="dashboard"  
          type="select"  
          label={t(  
            'content.profile.changeprofiledetails.card.input.dashboard.text'  
          )}  
          select  
          fullWidth  
          className={classes.textField}  
          component={TextField}  
          variant="standard"  
        >  
          <MenuItem key="default" value="default">{t('content.profile.changeprofiledetails.card.input.dashboard.sensight.location')}</MenuItem>  
          {dashboards.map(dashboard => (  
            <MenuItem key={dashboard.id} value={dashboard.id}>{dashboard.title}</MenuItem>  
          ))}  
        </Field>  
        <Field  
          name="tenant"  
          type="text"  
          label={t(  
            'content.profile.changeprofiledetails.card.input.tenant.text'  
          )}  
          fullWidth  
          disabled  
          className={classes.textField}  
          component={TextField}  
          variant="standard"  
        />  
        <ImdButton  
          variant="contained"  
          color="primary"  
          className={classes.button}  
          onClick={submitForm}  
          disabled={!dirty}  
        >  
          {t('content.profile.changeprofiledetails.card.button.text')}  
        </ImdButton>  
      </Form>  
    );  
  };  
  
  return (  
    <ImdCard  
      title={t('content.profile.changeprofiledetails.card.title')}  
      content={  
        <div>  
          {!isEmptyObject(profileDetails) ? (  
            <>  
              <Formik  
                enableReinitialize={true}  
                initialValues={{  
                  fullName: profileDetails.displayName,  
                  email: profileDetails.email,  
                  phonenumber: profileDetails.phoneNumber,  
                  dashboard: dashboards.some(dashboard => dashboard.id === profileDetails.dashboardId) ? profileDetails.dashboardId : "default",
                  tenant: getActiveTenant()  
                }}  
                validate={values => handleValidate(values)}  
                onSubmit={profileDetails =>  
                  updateProfileDetails(profileDetails)  
                }  
              >  
                {form}  
              </Formik>  
            </>  
          ) : (  
            <p>Fetching....</p>  
          )}  
        </div>  
      }  
    />  
  );  
}  
  
const useChangeProfileDetailsCardStyles = makeStyles(theme => ({  
  container: {  
    display: 'flex',  
    flexWrap: 'wrap'  
  },  
  textField: {  
    margin: theme.spacing(1)  
  },  
  button: {  
    marginLeft: theme.spacing(1),  
    marginRight: theme.spacing(1),  
    marginTop: theme.spacing(2)  
  }  
}));
