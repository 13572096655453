import { getThemeByTenantId } from 'apis/PlatformServiceApi';
import { current } from 'devextreme/ui/themes';

const THEME_ID = 'theme';

export const getSelectorColors = () => {
  return [
    '#deb887',
    '#5f9ea0',
    '#a52a2a',
    '#556b2f',
    '#d2691e',
    '#696969',
    '#cd5c5c',
    '#4682b4',
    '#ffa07a',
    '#778899',
    '#e6e6fa',
    '#32cd32',
    '#9370db',
    '#bc8f8f',
    '#87ceeb',
    '#9acd32',
    '#ffa500',
    '#daa520',
    '#ff69b4'
  ];
};

export const setActiveTheme = theme => {
  sessionStorage.setItem(THEME_ID, JSON.stringify(theme));
};

export async function setActiveThemeByTenant(user, tenant) {
  const theme = await GetTheme(user, tenant);
  sessionStorage.setItem(THEME_ID, theme.themeJson);
  setDevExtremeTheme(theme.name);
}

export const getActiveTheme = () => {
  try {
    return JSON.parse(sessionStorage.getItem(THEME_ID));
  } catch (ex) {
    console.error(ex);
    return null;
  }
};

async function GetTheme(user, tenant) {
  try {
    const themeJson = await getThemeByTenantId(user, tenant);
    return themeJson.data;
  } catch (ex) {
    console.error(ex);
    return null;
  }
}

const DEFAULT_THEME = 'imd';
const ALLOWED_THEME_NAMES = [DEFAULT_THEME, 'aquamoni', 'go_systems'];

function setDevExtremeTheme(name) {
  const lowered = name.toLowerCase();
  const theme = ALLOWED_THEME_NAMES.includes(lowered) ? lowered : DEFAULT_THEME;

  current(`material.${theme}`);
}
