import { getActiveTenant } from 'utils/tenantManager.js';

export const namespace = 'https://api.sensight.nl/';

export function getCurrentUserOrganization(currentUser) {
  try {
    var activeTenant = getCurrentUserTenant();
    var tenantsArray = currentUser[namespace + 'tenants'];
    var tenant = tenantsArray.filter(t => {
      return t.FriendlyId == activeTenant;
    });
    return tenant[0]['Organization'];
  } catch {
    return '';
  }
}

export function getCurrentUserTenant() {
  try {
    return getActiveTenant();
  } catch (e) {
    console.error(e);
    return '';
  }
}

export function getCurrentUserSensightId(currentUser) {
  try {
    return currentUser[namespace + 'id'];
  } catch (e) {
    console.error(e);
    return '';
  }
}

export function getCurrentUserLanguage(currentUser) {
  try {
    return currentUser[namespace + 'language'];
  } catch {
    return 'nl';
  }
}

export function getCurrentDashboard(currentUser) {
  try {
    return currentUser[namespace + 'dashboardId'] ?? 'default';
  } catch {
    return 'default';
  }
}

function GetUserRole(number) {
  if (number == 0) {
    return 'Reader';
  }
  if (number == 1) {
    return 'Contributor';
  }
  if (number == 2) {
    return 'Administrator';
  }
  if (number == 3) {
    return 'PlatformAdministrator';
  }
  return '';
}

export function getCurrentUserRole(currentUser) {
  try {
    var activeTenant = getCurrentUserTenant();
    var tenantsArray = currentUser[namespace + 'tenants'];
    var tenant = tenantsArray.filter(t => {
      return t.FriendlyId == activeTenant;
    });
    var role = tenant[0]['UserRole'];
    return GetUserRole(role);
  } catch {
    return 'Reader';
  }
}

export function currentUserIsAdmin(currentUser) {
  const currentUserRole = getCurrentUserRole(currentUser);
  if (
    currentUserRole === 'Administrator' ||
    currentUserRole === 'PlatformAdministrator'
  ) {
    return true;
  } else {
    return false;
  }
}

export function currentUserIsReader(currentUser) {
  const currentUserRole = getCurrentUserRole(currentUser);
  return currentUserRole === 'Reader';
}

export function getAllRoles() {
  return ['Reader', 'Contributor', 'Administrator', 'PlatformAdministrator'];
}
