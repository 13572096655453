import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme
} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import DevextremeDataGrid, {
  Editing,
  Paging,
  SearchPanel,
  ColumnFixing,
  Pager,
  Column,
  Sorting
} from 'devextreme-react/data-grid';
import { HeaderFilter } from 'devextreme-react/pivot-grid-field-chooser';
import { Export } from 'devextreme-react/pie-chart';
import clsx from 'clsx';
import MapIcon from '@mui/icons-material/Map';
import { Link } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper
} from '@mui/material';
import { Get } from 'apis/MeasurementODataApi';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { useSelector } from 'react-redux';

export default function LocationDataTable(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [devicePlacements, setDevicePlacements] = useState([]);
  const currentUser = useSelector(state => currentUserSelector(state));
  const data = props.data;
  const [reliabilityDialog, setReliabilityDialog] = useState(false);
  const getMuiTheme = createTheme(
    adaptV4Theme({
      overrides: {
        MUIDataTableToolbarSelect: {
          root: {}
        },
        MUIIconButton: {
          backgroundColor: 'green!important'
        }
      }
    })
  );

  const columns = (
    <>
      <Sorting mode="multiple"></Sorting>
      <Column
        sortIndex={1}
        sortOrder="asc"
        dataField={'name'}
        caption={t('content.locationstable.name')}
        cellRender={cell => {
          const link = `/management/locations/${cell.row.data.sensightId}`;
          const navLink = `https://maps.google.com/?q=${cell.row.data.latLngLatitude},${cell.row.data.latLngLongitude}`;
          return (
            <div className={classes.tableCell}>
              <a
                href={navLink}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.tableCell}
              >
                <MapIcon className={classes.mapIcon} />
              </a>
              <Link to={link} className={classes.tableCell}>
                {cell.value}
              </Link>
            </div>
          );
        }}
      />
      <Column dataField={'city'} caption={t('content.locationstable.city')} />
      <Column
        sortOrder="asc"
        sortIndex={0}
        dataField={'reliabilityPercentage'}
        caption={t('content.locationstable.reliabilitypercentage')}
        dataType="number"
        format="percent"
        width={75}
        allowGrouping={false}
        cellRender={cell => {
          return (
            <div
              className={clsx(
                classes.percentageButtonHover,
                classes.percentageButton,
                cell.value >= 0 && cell.value <= 10 && classes['afgekeurd'],
                cell.value > 10 && cell.value <= 39 && classes['twijfel'],
                cell.value >= 40 && classes['goed'],
                cell.row.data.online === false && classes['offline']
              )}
              onClick={() => {
                setReliabilityDialog(true);
                getDevices(cell.row.data.measurementId);
              }}
            >
              {cell.value}
            </div>
          );
        }}
      />
    </>
  );
  const configureEditing = () => {
    if (props.editing === undefined) {
      return (
        <Editing
          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}
        />
      );
    } else {
      return (
        <Editing
          mode={props.editing.mode}
          allowAdding={props.editing.allowAdding}
          allowDeleting={props.editing.allowDeleting}
          allowUpdating={props.editing.allowUpdating}
        />
      );
    }
  };

  const grid = useRef();

  const devextremeTable = (
    <div id={'data-grid-locations-dasboard'}>
      <DevextremeDataGrid
        ref={grid}
        dataSource={data}
        keyExpr={'id'}
        showBorders={false}
        hoverStateEnabled={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
      >
        <ColumnFixing enabled={true} />
        <Export
          enabled={false}
          fileName={t('content.management.contacts.title')}
        />
        {configureEditing()}
        <HeaderFilter visible={false} />
        <SearchPanel
          visible={true}
          width={200}
          placeholder={t('general.search')}
        />
        <Paging enabled={true} defaultPageSize={10} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[10, 15, 20, 50]}
          showInfo={false}
        />

        {columns.props.children}
      </DevextremeDataGrid>
    </div>
  );

  const handleClose = () => {
    setReliabilityDialog(false);
  };

  const getDevices = locationId => {
    let currentDate = new Date();
    const offset = currentDate.getTimezoneOffset();
    currentDate = new Date(currentDate.getTime() + offset * 60 * 1000);
    const date = currentDate.toISOString().split('T')[0];

    setDevicePlacements([]);
    const query =
      'deviceplacement?$filter=locationId eq ' +
      locationId +
      ' and startDateTime lt ' +
      date +
      ' and (endDateTime gt ' +
      date +
      ' or endDateTime eq null)&$expand=device($filter= online eq true and visible eq true;$expand=channels($filter= visible eq true;$expand=reliabilityreason))';

    Get(currentUser, query).then(result => {
      if (result.length > 0) {
        setDevicePlacements(result);
      } else {
        setDevicePlacements([]);
      }
    });
  };

  const dialog = (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Reliability table dialog"
      open={reliabilityDialog}
      fullWidth={true}
    >
      <DialogTitle id="Reliability table dialog title" onClose={handleClose}>
        {t('content.locationtable.reliabilitydialog.title')}
      </DialogTitle>
      <DialogContent dividers>
        {devicePlacements.map(devicePlacement => {
          return (
            <>
              <p>
                {devicePlacement.device.name} [
                {devicePlacement.device.reliabilityPercentage}%]
              </p>
              <TableContainer
                key={devicePlacement.sensightId}
                component={Paper}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {t('content.channelstable.displayLabel')}
                      </TableCell>
                      <TableCell>{t('content.channelstable.unit')}</TableCell>
                      <TableCell>
                        {t('content.channelstable.reliabilitypercentage')}
                      </TableCell>
                      <TableCell>
                        {t('content.channelstable.reliabilityreason')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {devicePlacement.device.channels.map(row => (
                      <TableRow key={row.externalChannelId}>
                        <TableCell component="th" scope="row">
                          {row.displayLabel}
                        </TableCell>
                        <TableCell>{row.unit}</TableCell>
                        <TableCell>
                          <div
                            className={clsx(
                              classes['percentageButton'],
                              row.reliabilityPercentage >= 0 &&
                                row.reliabilityPercentage <= 10 &&
                                classes['afgekeurd'],
                              row.reliabilityPercentage >= 10 &&
                                row.reliabilityPercentage <= 39 &&
                                classes['twijfel'],
                              row.reliabilityPercentage >= 40 &&
                                classes['goed'],
                              row.online === false && classes['offline']
                            )}
                          >
                            {row.reliabilityPercentage}
                          </div>
                        </TableCell>
                        <TableCell>{row.reliabilityReason.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          {t('general.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={getMuiTheme}>
          {dialog}
          {devextremeTable}
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    width: '114px',
    color: '#FFFFFF',
    paddingLeft: '8px',
    paddingBottom: '8px',
    paddingTop: '8px',
    borderRadius: '8px',
    flexGrow: '1'
  },
  totaal: {
    backgroundColor: '#0060A7',
    '&:hover': {
      backgroundColor: '#004a81'
    }
  },
  afgekeurd: {
    backgroundColor: '#c4161d',
    '&:hover': {
      backgroundColor: '#B9151B'
    }
  },
  twijfel: {
    backgroundColor: '#ba6f00',
    '&:hover': {
      backgroundColor: '#AD6700'
    }
  },
  goed: {
    backgroundColor: '#377f3a',
    '&:hover': {
      backgroundColor: '#347636'
    }
  },
  offline: {
    backgroundColor: '#8F8F8F',
    '&:hover': {
      backgroundColor: '#818181'
    }
  },
  percentageButton: {
    color: '#FFFFFF',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingBottom: '4px',
    paddingTop: '4px',
    borderRadius: '8px',
    textAlign: 'center'
  },
  percentageButtonHover: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  mapIcon: {
    position: 'relative',
    top: '5px',
    marginRight: '4px'
  },
  tableCell: {
    color: theme.palette.primary.main
  }
}));
