import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography, Switch } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Form, Field } from 'formik';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';

export const ProjectForm = ({
  viewModel,
  projectFormRef,
  onlineSwitchValue,
  selectedOrganizations,
  hideSwitchValue,
  setHideSwitchValue,
  setOnlineSwitchValue,
  selectedServiceContactPersons,
  selectedProjectManager,
  updateProjectDetails,
  setAutocompleteSelectedOrganizations,
  setAutocompleteServiceContactPersons
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormikWithRef
      ref={projectFormRef}
      initialValues={{
        online: onlineSwitchValue,
        visible: hideSwitchValue,
        organizationsCoupled: selectedOrganizations,
        serviceContactPerson: selectedServiceContactPersons,
        projectManager: selectedProjectManager,
        description: viewModel.location.description ?? ''
      }}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        values.organizationsCoupled = selectedOrganizations;
        values.serviceContactPerson = selectedServiceContactPersons;
        values.projectManager = selectedProjectManager;
        updateProjectDetails(values);
      }}
    >
      {props => (
        <Form onSubmit={props.submitForm}>
          <div className={clsx(classes.switchWrapper, classes.firstWrapper)}>
            <Typography variant="body1" className={classes.switchLabel}>
              {t('content.locationdashboard.form.locationVisible')}
            </Typography>
            <div className={classes.switchItem}>
              <Switch
                checked={hideSwitchValue}
                onChange={() => setHideSwitchValue(!hideSwitchValue)}
                value="hide"
                color="primary"
              />
            </div>
          </div>
          <div className={classes.switchWrapper}>
            <Typography variant="body1" className={classes.switchLabel}>
              {t('content.locationdashboard.form.online')}
            </Typography>
            <div className={classes.switchItem}>
              <Switch
                checked={onlineSwitchValue}
                onChange={() => setOnlineSwitchValue(!onlineSwitchValue)}
                value="online"
                color="primary"
              />
            </div>
          </div>
          <Autocomplete
            multiple
            filterSelectedOptions
            options={viewModel.organizations}
            getOptionLabel={option => option.name}
            defaultValue={selectedOrganizations}
            onChange={(event, newValue) => {
              setAutocompleteSelectedOrganizations(
                newValue.map(org => org.sensightId)
              );
            }}
            renderInput={params => (
              <TextField
                {...params}
                name="organizations"
                type="text"
                label={t('content.locationdashboard.form.organizations')}
                fullWidth
                className={classes.textField}
                variant="standard"
              />
            )}
          />
          <Autocomplete
            multiple
            filterSelectedOptions
            options={viewModel.contacts}
            getOptionLabel={option => option.email}
            defaultValue={selectedServiceContactPersons}
            onChange={(event, newValue) => {
              setAutocompleteServiceContactPersons(
                newValue.map(con => con.sensightId)
              );
            }}
            renderInput={params => (
              <TextField
                {...params}
                name="serviceContactPersons"
                type="text"
                label={t('content.locationdashboard.form.contactPersons')}
                fullWidth
                variant="standard"
              />
            )}
          />
          <Field
            id="description"
            type="text"
            onChange={props.handleChange}
            value={props.values.description}
            name="description"
            label={t('content.locationdashboard.form.description')}
            multiline
            rows="7"
            fullWidth
            component={TextField}
            variant="standard"
          />
        </Form>
      )}
    </FormikWithRef>
  );
};

const useStyles = makeStyles(() => ({
  firstWrapper: {
    marginBottom: '8px'
  },
  switchWrapper: {
    display: 'flex'
  },
  switchLabel: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  switchItem: {
    marginLeft: 'auto'
  },
  textField: {
    marginBottom: '8px'
  }
}));
