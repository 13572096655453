import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Content from '../../page/Content';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DashboardLocationsContainer } from './locations/DashboardLocationsContainer';
import { DashboardContainer } from './dashboard/DashboardContainer';
import { NewDashboardDialogContainer } from './newDashboardDialog/NewDashboardDialogContainer';
import { Link, useHistory } from 'react-router-dom';
import { Can } from 'components/imd-components/authorization/Can';
import {
  getCurrentDashboard,
  getCurrentUserRole
} from 'utils/currentUserReader';

export default function MyDashboardDisplay({
  setSaveButtonDisabled,
  allDashboards,
  currentDashboardId,
  fetchDashboards,
  editMode,
  fetchDashboard,
  fetchedDashboards,
  currentUser
}) {
  const { t } = useTranslation();
  const classes = useImdCardStyles();
  const [newDashboard, setNewDashboard] = useState(false);
  const [tabIndex, setTabIndex] = useState(-1);
  const [currentTabContent, setCurrentTabContent] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (allDashboards.length === 0) {
      fetchDashboards();
    }

    if (currentDashboardId.length > 0) {
      fetchDashboard(currentDashboardId);
    }
  }, [currentDashboardId]);

  useEffect(() => {
    setTabIndex(calculateIndex());
    setCurrentTabContent(
      allDashboards.map(item => {
        const tabKey = allDashboards.findIndex(d => d.id === item.id) + 1;

        return (
          <Typography key={item.id} component="div" role="tabpanel">
            <TabContent tabKey={tabKey} item={item} />
          </Typography>
        );
      })
    );
  }, [allDashboards]);

  useEffect(() => {
    setTabIndex(calculateIndex());
  }, [fetchedDashboards]);

  // redirect to default dashboard set by user
  useEffect(() => {
    const defaultDashboardId = getCurrentDashboard(currentUser);
    if (
      defaultDashboardId &&
      allDashboards.length > 0 &&
      history.location.pathname === '/'
    ) {
      const dashboard = allDashboards.find(d => d.id === defaultDashboardId);
      if (dashboard) {
        history.push(`/dashboard/${defaultDashboardId}`);
      }
    }
  }, [allDashboards, history, currentUser]);

  const handleOk = () => {
    setNewDashboard(false);
  };

  const calculateIndex = () => {
    if (!fetchedDashboards) {
      return -1;
    }

    if (allDashboards.length > 0) {
      const result = allDashboards.findIndex(d => d.id === currentDashboardId);
      if (result === -1) {
        return 0;
      }
      return result + 1;
    }
    return 0;
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  function LinkTab(props) {
    return <Tab component={Link} {...props} />;
  }

  const renderTabLabels = () => {
    return allDashboards.map(item => {
      return (
        <LinkTab
          key={item.id}
          label={item.title}
          to={'/dashboard/' + item.id}
        />
      );
    });
  };

  const TabContent = props => {
    const tabNumber = tabIndex === 0 ? 0 : tabIndex - 1;
    if (currentDashboardId === props.item.id) {
      return (
        <DashboardContainer
          editMode={editMode}
          selected={allDashboards[tabNumber]}
        />
      );
    }
    return null;
  };

  const tabContent = (
    <>
      <Tabs
        value={tabIndex === -1 ? 0 : tabIndex}
        onChange={handleChange}
        scrollButtons="auto"
        variant="scrollable"
        aria-label="tabs"
        classes={{
          root: classes.tabsRoot,
          indicator: classes.indicator
        }}
      >
        <LinkTab label={t('content.dashboard.locations')} to={'/locations'} />
        {renderTabLabels()}
        <Can
          role={getCurrentUserRole(currentUser)}
          perform="dashboard:manage"
          yes={() => (
            <Tab
              label={t('content.dashboard.newDashboard')}
              onClick={() => {
                setNewDashboard(true);
              }}
            />
          )}
          no={() => null}
        />
      </Tabs>
      <Typography component="div" role="tabpanel" hidden={tabIndex !== 0}>
        <DashboardLocationsContainer render={tabIndex === 0} />
      </Typography>
      {currentTabContent}
      <NewDashboardDialogContainer
        open={newDashboard}
        setSaveButtonDisabled={setSaveButtonDisabled}
        setOpen={() => setNewDashboard(false)}
        handleOk={handleOk}
      />
    </>
  );

  return (
    <Content
      title={t('content.dashboard.title')}
      content={tabContent}
      key="1"
    />
  );
}

const useImdCardStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  card: {
    minWidth: 275
  },
  tabs: {},
  tabsRoot: {
    backgroundColor: '#FFFFFF',
    borderRadius: '5px 5px 0 0',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
    marginBottom: '8px'
  },
  tab: {
    color: theme.palette.primary.main
  },
  indicator: {
    backgroundColor: theme.palette.primary.main
  },
  inputField: {
    marginBottom: '8px'
  }
}));
